import React from "react";
import Slider from "react-slick";
import { Nav, Container, Row, Col } from "react-bootstrap";
import { BsArrowUpRight } from "react-icons/bs";

const MainSlider = (props) => {
  const { children } = props;
  return (
    <div>
      <section className={props.ClassProperty}>
        <Container>
          <Row className="d-flex justify-content-center align-items-end pb-2">
            <Col md={8}>
              <h2 className="text-capitalize text-shark font-rubic fw-500">
                {props.SectionTitle}
              </h2>
              <p className="mb-0 fs-15 font-rubic fw-400 text-silver-chalice">
                {props.SectionSubTitle}
              </p>
            </Col>
            <Col md={4} className="text-end">
              <Nav.Link className="text-capitalize btn btn-outline-secondary d-inline-block px-4 py-1 rounded-pill btn-semore">
                see all projects <BsArrowUpRight />
              </Nav.Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Slider {...props.SliderSetting} className="py-3 m-0">
                {children}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default MainSlider;
