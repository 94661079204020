import React from "react";
import SignUp from "../SignUp";
import { Button, Container, Nav, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
const Login = () => {
  return (
    <>
      <SignUp>
        <div className="px-3 px-md-4 px-lg-5 mx-0 mx-md-4 mx-lg-5 py-5 py-md-4 py-lg-0 signup-form">
          <Form>
            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-open fw-600">
                  log in
                </h2>
                <p className="fs-16 fw-400 font-open text-dove-gray">
                  Start with your free account today
                </p>
              </Col>
              <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
              <Col xs={12} className="d-grid">
                <Link
                  to="/dashboard"
                  size="lg"
                  className="btn btn-primary submit-btn"
                >
                  Log In
                </Link>
              </Col>
            </Row>
            <Col xs={12} className="d-flex justify-content-between">
              <Link to="/signupform">Sign Up</Link>
              <Link to="/forgort-password-reqest">Forgot Password</Link>
            </Col>
          </Form>
        </div>
      </SignUp>
    </>
  );
};

export default Login;
