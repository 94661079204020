import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import EventSLider from "../../../component/EventSLider";
import OurAgents from "../../../component/OurAgents";
import Filters from "../../../component/Filters";
import Services from "../../../component/Home/Services";
import HeroSlider from "../../../component/HeroSlider";
import OurBuilders from "../../../component/OurBuilders";
import EnquryForm from "../../../component/Home/EnquryForm";
import MainSlider from "../../../component/sliders/MainSlider";
import {
  RecomedSliderSettings,
  ShortSliderSetting,
} from "../../../data/SliderSettings";
import { RecomedSlides, ShortVideoSLides } from "../../../data/Data";
import VideoCardDetailed from "../../../component/VideoCardDetailed";
import ShotVideoCard from "../../../component/ShortVideoCard";

const Home = () => {
  return (
    <>
      <Header />
      <div className="header-divider"></div>
      <HeroSlider />
      <section className="filters py-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={10}>
              <h2 className="mb-4 text-smoky-black font-rubic fw-400">
                Let's find your{" "}
                <span className="text-royal-blue">dream property...</span>
              </h2>
              <Filters />
            </Col>
          </Row>
        </Container>
      </section>
      <MainSlider
        SliderSetting={RecomedSliderSettings}
        ClassProperty="py-5"
        SectionTitle="Recomended projects"
        SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
      >
        {RecomedSlides.map((Item) => (
          <div key={Item.id}>
            <VideoCardDetailed
              ClassProperty="m-1 recomend-card"
              title={Item.title}
              propertImage={Item.propertImage}
              clientname={Item.clientname}
              timeline={Item.timeline}
            />
          </div>
        ))}
      </MainSlider>
      <MainSlider
        SliderSetting={ShortSliderSetting}
        ClassProperty="py-5"
        SectionTitle="Short Video / Real (Max 30 sec)"
        SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
      >
        {ShortVideoSLides.map((Item) => (
          <div key={Item.id}>
            <ShotVideoCard
              ClassProperty=""
              Thumbnel={Item.url}
              title={Item.title}
            />
          </div>
        ))}
      </MainSlider>
      <EventSLider />

      <Services />
      <OurBuilders />
      <section className="py-5 bg-concrete">
        <Container>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
        </Container>
      </section>
      <OurAgents />
      <EnquryForm />
      <MainSlider
        SliderSetting={RecomedSliderSettings}
        ClassProperty="py-5 bg-darklite"
        SectionTitle="New launch projects"
        SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
      >
        {RecomedSlides.map((Item) => (
          <div key={Item.id}>
            <VideoCardDetailed
              ClassProperty="m-1 recomend-card"
              title={Item.title}
              propertImage={Item.propertImage}
              clientname={Item.clientname}
              timeline={Item.timeline}
            />
          </div>
        ))}
      </MainSlider>
      <Footer />
    </>
  );
};

export default Home;
