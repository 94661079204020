import sliderimg from "../assets/img/slider.png";
import shortv from "../assets/img/shortv.png";



  export const RecomedSlides = [
    {
      id: 1,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      propertImage: sliderimg,
      clientname: "pranay masulkar",
      timeline: "4 monts",
    },
    {
      id: 2,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      propertImage: sliderimg,
      clientname: "pranay masulkar",
      timeline: "4 monts",
    },
    {
      id: 3,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      propertImage: sliderimg,
      clientname: "pranay masulkar",
      timeline: "4 monts",
    },
    {
      id: 4,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      propertImage: sliderimg,
      clientname: "pranay masulkar",
      timeline: "4 monts",
    },
    {
      id: 5,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      propertImage: sliderimg,
      clientname: "pranay masulkar",
      timeline: "4 monts",
    },
    {
      id: 6,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      propertImage: sliderimg,
      clientname: "pranay masulkar",
      timeline: "4 monts",
    },
  ];

  
  export const ShortVideoSLides = [
    {
      id: 1,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 2,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 3,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 4,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 5,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 6,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 7,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 8,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 9,
      title: "Nandanvan Project",
      url: shortv,
    },
    {
      id: 10,
      title: "Nandanvan Project",
      url: shortv,
    },
  ];


  export const TopAgentSlides = [
    {
      id: 1, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 2, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 3, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 4, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 5, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 6, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 7, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 8, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 9, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 10, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 11, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
    {
      id: 12, title: 'Pranay Masulkar',
      url: 'https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg',
    },
  ];


