import React from "react";
import Slider from "react-slick";
import { Card, Container, Row, Col } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";

const TopAgentCard = (props) => {
  const TopAgentSlides = [
    {
      id: 1,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 2,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 3,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 4,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 5,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 6,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 7,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 8,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 9,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
    {
      id: 10,
      title: "Pranay Masulkar",
      url: "https://www.shutterstock.com/image-vector/man-silhouette-profile-picture-vector-600w-151265393.jpg",
    },
  ];
  return (
    <>
      <Card className="m-1">
        <Card.Img variant="top" src={props.Thumbnel} />
        <Card.Body className="d-flex justify-content-between align-items-center">
          <Card.Title className="fs-15 mb-0">{props.title}</Card.Title>
          <HiDotsVertical size={18} />
        </Card.Body>
      </Card>
    </>
  );
};

export default TopAgentCard;
