import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminPannelDashBoardLayout from "../../../layout/DashBoardLayouts/AdminPannelDashBoardLayout";

const CustomerData = [
  {
    SrNo: 1,
    Customer_Id: 1345,
    Title: "Events",
    Email_Id: "vickym@gmail.com",
    EventDate: "10/08/2021",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
  {
    SrNo: 2,
    Customer_Id: 2345,
    Title: "Events",
    EventDate: "10/08/2021",
    Email_Id: "vickym@gmail.com",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
  {
    SrNo: 3,
    Customer_Id: 3345,
    Title: "Events",
    EventDate: "10/08/2021",
    Email_Id: "vickym@gmail.com",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
  {
    SrNo: 4,
    Customer_Id: 4345,
    Title: "Events",
    EventDate: "10/08/2021",
    Email_Id: "vickym@gmail.com",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
];
const Events = () => {
  return (
    <>
      <AdminPannelDashBoardLayout>
        <Row>
          <Col xs={12}>
            <Card className="mb-4">
              <Card.Header>
                <h3 className="my-3">Events</h3>
              </Card.Header>
              <Card.Body>
                <Table>
                  <thead>
                    <tr>
                      <td scope="col">Sr. No.</td>
                      <td scope="col">Event Id</td>
                      <td scope="col">Event Title</td>
                      <td scope="col">Event Date</td>
                      <td scope="col">Location</td>
                      <td scope="col">listed By</td>
                    </tr>
                  </thead>
                  <tbody>
                    {CustomerData.map((Item) => (
                      <tr key={Item.index}>
                        <td scope="row">{Item.SrNo}</td>
                        <td>
                          <Link to="/events-details-single">
                            {" "}
                            {Item.Customer_Id}
                          </Link>
                        </td>
                        <td>{Item.Title}</td>
                        <td>{Item.EventDate}</td>
                        <td>{Item.Location}</td>
                        <td>{Item.Name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminPannelDashBoardLayout>
    </>
  );
};

export default Events;
