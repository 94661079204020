import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import AdminPannelDashBoardLayout from "../../../../layout/DashBoardLayouts/AdminPannelDashBoardLayout";

const CustomerActivityTable = () => {
  return (
    <>
      <AdminPannelDashBoardLayout>
        <Container>
          <Row>
            <Col>
              <h3 className="text-capitalize mb-4">
                posted property requirement
              </h3>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <td>Sr. no.</td>
                    <td>property id</td>
                    <td>title</td>
                    <td>date</td>
                    <td>posted by</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>123</td>
                    <td>property title</td>
                    <td>10-2-2023</td>
                    <td>pranay masulkar</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>123</td>
                    <td>property title</td>
                    <td>10-2-2023</td>
                    <td>pranay masulkar</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>123</td>
                    <td>property title</td>
                    <td>10-2-2023</td>
                    <td>pranay masulkar</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </AdminPannelDashBoardLayout>
    </>
  );
};

export default CustomerActivityTable;
