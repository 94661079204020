import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserDashBoardLayout from "../../layout/DashBoardLayouts/UserDashBoardLayout";
import Filters from "../../component/Filters";
import FileUploadSection from "../../component/FileUploadSection";
import MainSlider from "../../component/sliders/MainSlider";
import ShotVideoCard from "../../component/ShortVideoCard";
import {
  DashboardShortSliderSetting,
  TopAgentsettings,
} from "../../data/SliderSettings";
import { ShortVideoSLides, TopAgentSlides } from "../../data/Data";
import TopAgentCard from "../../component/TopAgentCard";
import Slider from "react-slick";
import {
  UserDashBoardHero,
  RecomedSliderSettings,
} from "../../data/SliderSettings";
import { RecomedSlides } from "../../data/Data";
import VideoCardDetailed from "../../component/VideoCardDetailed";
import BuilderDashBoardLayout from "../../layout/DashBoardLayouts/BuilderDashBoardLayout";

const BuilderDashboard = (props) => {
  const { children } = props;
  const UserDashBoardHeroContent = [
    {
      id: 1,
      title: "Pranay Masulkar",
      url: "https://i.pinimg.com/736x/a9/fe/fe/a9fefe0767b468591389c5d87eb581d0.jpg",
    },
    {
      id: 2,
      title: "Pranay Masulkar",
      url: "https://i.pinimg.com/736x/a9/fe/fe/a9fefe0767b468591389c5d87eb581d0.jpg",
    },
    {
      id: 3,
      title: "Pranay Masulkar",
      url: "https://i.pinimg.com/736x/a9/fe/fe/a9fefe0767b468591389c5d87eb581d0.jpg",
    },
    {
      id: 4,
      title: "Pranay Masulkar",
      url: "https://i.pinimg.com/736x/a9/fe/fe/a9fefe0767b468591389c5d87eb581d0.jpg",
    },
  ];
  return (
    <>
      <BuilderDashBoardLayout>
        <Container>
          <Row>
            <Col className="p-0 mb-4">
              <Slider {...UserDashBoardHero} className="py-3 mx-4">
                {UserDashBoardHeroContent.map((Item) => (
                  <div key={Item.id}>
                    <div>
                      <img src={Item.url} alt="" />
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>

          {/* <Row>
            <Col>
            <FileUploadSection />
            </Col>
          </Row> */}
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <h2 className="mb-4">Let's fine your dream property...</h2>
            </Col>
            <Col xs={12}>
              <Filters />
            </Col>
          </Row>

          <section className="filters py-5">
            <Row>
              {RecomedSlides.map((Item) => (
                <Col className="mt-3">
                  <div key={Item.id}>
                    <VideoCardDetailed
                      ClassProperty="m-1 recomend-card"
                      title={Item.title}
                      propertImage={Item.propertImage}
                      clientname={Item.clientname}
                      timeline={Item.timeline}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </section>

          <MainSlider
            SliderSetting={RecomedSliderSettings}
            ClassProperty="py-5"
            SectionTitle="Recomended projects"
            SectionSubTitle="
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eveniet, ipsa illo."
          >
            {RecomedSlides.map((Item) => (
              <div key={Item.id}>
                <VideoCardDetailed
                  ClassProperty="m-1 recomend-card"
                  title={Item.title}
                  propertImage={Item.propertImage}
                  clientname={Item.clientname}
                  timeline={Item.timeline}
                />
              </div>
            ))}
          </MainSlider>
          <MainSlider
            SliderSetting={RecomedSliderSettings}
            ClassProperty="py-5"
            SectionTitle="Featured projects"
            SectionSubTitle="
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eveniet, ipsa illo."
          >
            {RecomedSlides.map((Item) => (
              <div key={Item.id}>
                <VideoCardDetailed
                  ClassProperty="m-1 recomend-card"
                  title={Item.title}
                  propertImage={Item.propertImage}
                  clientname={Item.clientname}
                  timeline={Item.timeline}
                />
              </div>
            ))}
          </MainSlider>
          <MainSlider
            SliderSetting={DashboardShortSliderSetting}
            ClassProperty="py-5"
            SectionTitle="Property Shorts"
            SectionSubTitle="
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eveniet, ipsa illo."
          >
            {ShortVideoSLides.map((Item) => (
              <div key={Item.id}>
                <ShotVideoCard
                  ClassProperty=""
                  Thumbnel={Item.url}
                  title={Item.title}
                />
              </div>
            ))}
          </MainSlider>

          <MainSlider
            SliderSetting={RecomedSliderSettings}
            ClassProperty="py-5"
            SectionTitle="Top Developers projects"
            SectionSubTitle="
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eveniet, ipsa illo."
          >
            {RecomedSlides.map((Item) => (
              <div key={Item.id}>
                <VideoCardDetailed
                  ClassProperty="m-1 recomend-card"
                  title={Item.title}
                  propertImage={Item.propertImage}
                  clientname={Item.clientname}
                  timeline={Item.timeline}
                />
              </div>
            ))}
          </MainSlider>
          <MainSlider
            SliderSetting={TopAgentsettings}
            ClassProperty="py-5"
            SectionTitle="Top Agents"
            SectionSubTitle="
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eveniet, ipsa illo."
          >
            {TopAgentSlides.map((Item) => (
              <div key={Item.id}>
                <TopAgentCard
                  ClassProperty=""
                  Thumbnel={Item.url}
                  title={Item.title}
                />
              </div>
            ))}
          </MainSlider>
        </Container>
      </BuilderDashBoardLayout>
    </>
  );
};

export default BuilderDashboard;
