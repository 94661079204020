import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Collapse,
  Form,
  Nav,
  Image,
} from "react-bootstrap";
import AdminPannelDashBoardLayout from "../../../layout/DashBoardLayouts/AdminPannelDashBoardLayout";
import BigImg from "../../../assets/img/photo1.png";
import { IoShareSocialSharp } from "react-icons/io5";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";
const EventsDetailsSingle = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AdminPannelDashBoardLayout>
        <section className="pb-5">
          <Container>
            {/* New sction */}
            <Row>
              <Col md={12}>
                {/* New section */}
                <Card className="shadow mb-5 p-2">
                  <Card.Body>
                    <Row>
                      <Col md={5}>
                        <div>
                          {/* <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/1hLBCOlptq8"
                  ></iframe> */}
                          <Image
                            src={BigImg}
                            className="img-fluid"
                            style={{ minHeight: "280px" }}
                          />
                        </div>
                        <div className="pt-2 d-flex justify-content-between">
                          <Nav className="justify-content-end" as="ul">
                            <Nav.Item as="li">
                              <Nav.Link href="#">
                                <AiOutlineLike size={20} />
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Nav.Link eventKey="link-1">
                                <AiOutlineDislike size={20} />
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Nav className="justify-content-end" as="ul">
                            <Nav.Item as="li">
                              <Nav.Link eventKey="link-2">
                                <IoShareSocialSharp size={20} />
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </Col>
                      <Col md={7}>
                        <Row>
                          <Col md={12}>
                            <h1 className="text-capitalize text-shark font-rubic fw-500">
                              Real Estate Property Sale
                            </h1>
                          </Col>
                        </Row>
                        <Form>
                          <Form.Group as={Row} className="mb-0" controlId="">
                            <Form.Label
                              className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                              column
                              sm="3"
                            >
                              Event start:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                className="text-code-gray fs-16 fw-500 text-capitalize"
                                plaintext
                                readOnly
                                defaultValue="12/4/2023 12:20 pM "
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-0" controlId="">
                            <Form.Label
                              className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                              column
                              sm="3"
                            >
                              event end:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                className="text-code-gray fs-16 fw-500 text-capitalize"
                                plaintext
                                readOnly
                                defaultValue="12/4/2023 12:20 pM "
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-0" controlId="">
                            <Form.Label
                              className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                              column
                              sm="3"
                            >
                              venue:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                className="text-code-gray fs-16 fw-500 text-capitalize"
                                plaintext
                                readOnly
                                defaultValue="center point hotel, nagpur (MH) - 440034"
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-0" controlId="">
                            <Form.Label
                              className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                              column
                              sm="3"
                            >
                              location:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                className="text-code-gray fs-16 fw-500 text-capitalize"
                                plaintext
                                readOnly
                                defaultValue="Trimurti nagar, nagpur, nagpur mahasrastra"
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-0" controlId="">
                            <Form.Label
                              className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                              column
                              sm="3"
                            >
                              Event Brief:
                            </Form.Label>
                            <Col sm="9">
                              <p className="fs-16 font-rubic fw-400 text-silver-chalice mt-2 mb-1">
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Sequi, fugiat? Reprehenderit
                                animi dolore quos reiciendis expedita maxime
                              </p>
                              <Button
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                variant="outline-primary border-0 p-0"
                              >
                                Read More...
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                        <div>
                          <div className="text-center my-3"></div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <Collapse in={open}>
                          <div id="example-collapse-text">
                            <Row>
                              <Col xs>
                                <p className="fs-16 font-rubic fw-400 text-silver-chalice mb-0">
                                  Lorem ipsum dolor, sit amet consectetur
                                  adipisicing elit. Consequuntur blanditiis
                                  voluptatibus maxime explicabo vitae autem
                                  dicta dolores, ipsa iure quibusdam aspernatur
                                  vero. Voluptatum commodi ea molestiae ipsam
                                  eligendi officia deleniti!
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* New section */}
              </Col>
            </Row>
          </Container>
        </section>
      </AdminPannelDashBoardLayout>
    </>
  );
};

export default EventsDetailsSingle;
