import React from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import VideoCard from "../../../component/VideoCard";
const OwnerCard = () => {
  return (
    <>
      <Card className="shadow mb-5 p-2">
        <Card.Body>
          <h4 className="mb-4 text-code-gray font-rubic fw-300 text-capitalize border-bottom pb-2">
            Owner
          </h4>
          <p className="mb-2 fs-18 font-rubic text-capitalize fw-400 text-silver-chalice">
            Pranay Masulkar
          </p>
          <p className="text-code-gray fs-18 fw-400 text-capitalize">
            +91 8624043380
          </p>
          <div className="text-center mt-5">
            <Button variant="primary" className="abtn-primary-custom w-100">
              Enquiry Now
            </Button>{" "}
          </div>
        </Card.Body>
      </Card>
      <Card className="shadow mb-5 p-2">
        <Card.Body>
          <h4 className="mb-4 text-code-gray font-rubic fw-300 text-capitalize border-bottom pb-2">
            Contact Owner
          </h4>
          <p>Pranay Masulkar</p>
          <p>+91 8624043380</p>
          <Row>
            <Form.Group as={Col} md={12} className="mb-3">
              <Form.Control
                type="text"
                className="border-top-0 border-start-0 border-end-0 rounded-0 ps-0"
                placeholder="name"
              />
            </Form.Group>
            <Form.Group as={Col} md={12} className="mb-3">
              <Form.Control
                type="text"
                className="border-top-0 border-start-0 border-end-0 rounded-0 ps-0"
                placeholder="email id"
              />
            </Form.Group>
            <Form.Group as={Col} xs={3}>
              <Form.Select className="border-top-0 border-start-0 border-end-0 rounded-0 ps-0">
                <option>+91</option>
                <option value="1">89</option>
                <option value="2">68</option>
                <option value="3">98</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={9} className="mb-3 ps-0">
              <Form.Control
                type="text"
                className="border-top-0 border-start-0 border-end-0 rounded-0 ps-0"
                placeholder="mobile number"
              />
            </Form.Group>
            <Col xs={12}>
              <Form.Check
                className="text-start fs-15 text-code-gray"
                type="checkbox"
                label="I wish to check my Home Loan eligibility"
              />
            </Col>
          </Row>
          <div className="text-center mt-4">
            <Button
              variant="primary"
              className="abtn-primary-custom w-100 
                      "
            >
              Submit
            </Button>{" "}
          </div>
        </Card.Body>
      </Card>
      <VideoCard />
    </>
  );
};

export default OwnerCard;
