import React from "react";
import { Col, Container, Row, Card, Image } from "react-bootstrap";
import UserPic from "../assets/img/userpic.png";
import { IoCall } from "react-icons/io5";
import { GrMail } from "react-icons/gr";
import { HiLocationMarker } from "react-icons/hi";
const UserDetailsCard = (props) => {
  return (
    <>
      <Card className="shadow mb-5 p-2">
        <Card.Body>
          <Row>
            <Col md={3} className="d-flex align-items-stretch">
              <Image src={UserPic} className="img-fluid" />
            </Col>
            <Col md={9}>
              <h3 className="text-royal-blue fw-400 text-capitalize">
                {props.Name}
              </h3>
              <p className="fs-15 fw-400 text-code-gray2 font-rubic mb-2">
                Id {props.UserId}
              </p>
              <p className="fs-15 fw-400 text-code-gray2 font-rubic mb-2">
                RERA No. {props.ReraNo}
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                <IoCall className="text-code-gray2" />
                {props.MobileNo}
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                <GrMail className="text-code-gray2" /> {props.EmalId}
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                <HiLocationMarker className="text-code-gray2" /> {props.Address}
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                Speciality Market :{" "}
                <span className="text-code-gray2 fw-500 text-capitalize">
                  Sale
                </span>
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                Years of Experience :
                <span className="text-code-gray2 fw-500 text-capitalize">
                  {props.Experiance}
                </span>
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before final copy is available.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserDetailsCard;
