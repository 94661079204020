import React from "react";
import {
  Button,
  Container,
  Nav,
  Row,
  Col,
  Carousel,
  Card,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminAuthLayout from "./AdminAuthLayout";

const AdminForgortPasswordReqest = () => {
  return (
    <>
      <AdminAuthLayout>
        <div className="px-3 px-md-4 px-lg-5 mx-0 mx-md-4 mx-lg-5 py-5 signup-form bg-white rounded">
          <Form>
            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-open fw-600 mb-4">
                  forgot password
                </h2>
              </Col>
              <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="email"
                  placeholder="Enter email / Mobile No."
                />
              </Form.Group>
              <Col xs={12} className="d-grid">
                <Link
                  to="/admin-forgort-password"
                  size="lg"
                  className="btn btn-primary submit-btn"
                >
                  Send Code
                </Link>
              </Col>
            </Row>
          </Form>
        </div>
      </AdminAuthLayout>
    </>
  );
};

export default AdminForgortPasswordReqest;
