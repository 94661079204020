import React from "react";
import { Card, Row, Col, Image, Button } from "react-bootstrap";
import UserPic from "../../../assets/img/userpic.png";
import { IoCall } from "react-icons/io5";
import { GrMail } from "react-icons/gr";
import { HiLocationMarker } from "react-icons/hi";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
const UseerDetailCard = () => {
  return (
    <>
      <Card className="shadow mb-5 p-2">
        <Card.Body>
          <Row>
            <Col md={12}>
              <div className="d-flex justify-content-between align-items-start">
                <Image
                  src={UserPic}
                  className="img-fluid w-25 d-inline-block mb-3"
                />
                <Link
                  to="/edit-user-details"
                  variant="primary"
                  className="btn btn-primary menu-btn p-0 border-0"
                >
                  <FaEdit size={18} />
                </Link>
              </div>
              <h3 className="text-royal-blue fw-400 text-capitalize">
                pranay masulkar
              </h3>
              <p className="fs-15 fw-400 text-code-gray2 font-rubic mb-2">
                RERA No. 68456515JHGHJUB
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                <IoCall className="text-code-gray2" /> 8208927996
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                <GrMail className="text-code-gray2" /> pravinfreelance@gmail.com
              </p>
              <p className="fs-15 fw-400 text-silver-chalice font-rubic mb-2">
                <HiLocationMarker className="text-code-gray2" /> Gopal Nagar,
                Nagpur
              </p>
              <Button
                variant="primary"
                className="w-100 mt-3 text-uppercase fw-700"
              >
                Log out
              </Button>
            </Col>
            {/* fsf */}
            {/* fsf */}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UseerDetailCard;
