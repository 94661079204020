import React from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
const ContactForm = () => {
  return (
    <>
      <div className="px-0 px-md-5 mx-0 mx-md-5 signup-form">
        <Form>
          <Row className="mb-3">
            <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
              <Form.Control
                size="lg"
                className="border-top-0 border-start-0 border-end-0 rounded-0 bg-transparent"
                type="text"
                placeholder="Your Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
              <Form.Control
                size="lg"
                className="border-top-0 border-start-0 border-end-0 rounded-0 bg-transparent"
                type="email   "
                placeholder="Email address"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
              <Form.Control
                size="lg"
                className="border-top-0 border-start-0 border-end-0 rounded-0 bg-transparent"
                type="email"
                placeholder="Phone"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
              <Form.Control
                size="lg"
                className="border-top-0 border-start-0 border-end-0 rounded-0 bg-transparent"
                type="text"
                placeholder="Subject"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
              <Form.Control
                size="lg"
                className="border-top-0 border-start-0 border-end-0 rounded-0 bg-transparent h-50"
                type="text"
                placeholder="Message"
              />
            </Form.Group>
            <Col xs={12} className="text-center text-md-start">
              <Button size="lg" className="btn btn-primary submit-btn px-5">
                Send Message
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ContactForm;
