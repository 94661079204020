import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Website/Home/Home";
// import Dashboard from "./views/Dashboard/Dashboard";
import UserDashboard from "./AllPanels/UserPannel/UserDashboard";
import PropertyForm from "./AllPanels/UserPannel/properties/PropertyForm";
import SignUp from "./layout/AuthLayout/SignUp/SignUp";
import UpdateDetail from "./layout/AuthLayout/SignUp/UpdateDetail";
import SignUpForm from "./layout/AuthLayout/SignUp/SignUpForm";
import PropertyDetails from "./AllPanels/UserPannel/properties/PropertyDetails";
import PropertyDetailsSingle from "./AllPanels/UserPannel/properties/PropertyDetailsSingle";
import EventList from "./views/Event/EventList";
import EventRegistration from "./views/Event/EventRegistration";
import AddPropertyVideo from "./AllPanels/UserPannel/properties/AddPropertyVideo";
import Login from "./layout/AuthLayout/SignUp/Login/Login";
import ForgortPasswordReqest from "./layout/AuthLayout/SignUp/ForgotPassword/ForgortPasswordReqest";
import ForgortPassword from "./layout/AuthLayout/SignUp/ForgotPassword/ForgortPassword";
import UserDetails from "./AllPanels/UserPannel/UserDetails/UserDetails";
import EventDetails from "./views/Event/EventDetails";
import Contact from "./views/Contact/Contact";
import About from "./views/Website/About/About";
import EditUserDetails from "./AllPanels/UserPannel/UserDetails/EditUserDetails";
import AgentLogin from "./layout/AuthLayout/SignUp/Login/AgentLogin";
import AdminDashboard from "./AdminPannel/AdminDashboard/AdminDashboard";
import Projects from "./AdminPannel/views/projects/Projects";
import Properties from "./AdminPannel/views/properties/Properties";
import Customers from "./AdminPannel/views/userManagement/customer/Customer";
import CustomerSingleDetails from "./AdminPannel/views/userManagement/customer/CustomerSingleDetails";
import CustomerActivityTable from "./AdminPannel/views/userManagement/customer/CustomerActivityTable";
import Agents from "./AdminPannel/views/userManagement/agents/Agents";
import AgentsSingleDetails from "./AdminPannel/views/userManagement/agents/AgentsSingleDetails";
import AgentsActivityTable from "./AdminPannel/views/userManagement/agents/AgentsActivityTable";
import Builder from "./AdminPannel/views/userManagement/builder/Builder";
import BuilderActivityTable from "./AdminPannel/views/userManagement/builder/BuilderActivityTable";
import BuilderSingleDetails from "./AdminPannel/views/userManagement/builder/BuilderSingleDetails";
import PropertiesDetailsSingle from "./AdminPannel/views/properties/PropertiesDetailsSingle";
import Events from "./AdminPannel/views/events/Events";
import EventsDetailsSingle from "./AdminPannel/views/events/EventsDetailsSingle";
import UnderDev from "./views/UnderDeve/UnderDev";
import FindAgents from "./views/Agents/FindAgents";
import UserHistory from "./views/History/UserHistory";
import FindBuilders from "./views/Builders/FindBuilders";
import FindProperty from "./AllPanels/UserPannel/properties/FindProperty";
import News from "./views/News";
import AdminLogin from "./AdminPannel/AdminAuth/AdminLogin";
import AdminForgortPassword from "./layout/AuthLayout/SignUp/ForgotPassword/ForgortPassword";
import AdminForgortPasswordReqest from "./AdminPannel/AdminAuth/AdminForgortPasswordReqest";
import AllPagesDemo from "./views/AllPagesDemo/AllPagesDemo";
import PostYourProperty from "./AllPanels/UserPannel/properties/PostYourProperty";
import ListedProperty from "./AllPanels/UserPannel/properties/ListedProperty";
import AgentsList from "./views/Agents/AgentsList";
import BuilderDashboard from "./AllPanels/BuilderPanel/BuilderDashboard";
import BuilderOfferce from "./AllPanels/BuilderPanel/BuilderOfferce";
import BuilderListedProperty from "./AllPanels/BuilderPanel/BuilderListedProperty";
import BuilderAgentList from "./AllPanels/BuilderPanel/BuilderAgentList";
import BuilderEventList from "./AllPanels/BuilderPanel/BuilderEventList";
import AgentDashboard from "./AllPanels/AgentPannel/AgentDashboard";
import AgentEventList from "./AllPanels/AgentPannel/AgentEventList";
import AgentListedProperty from "./AllPanels/AgentPannel/AgentListedProperty";
import AgentOfferce from "./AllPanels/AgentPannel/AgentOfferce";
import AgentNews from "./AllPanels/AgentPannel/AgentNews";
import AgentEventDetails from "./AllPanels/AgentPannel/AgentEventDetails";
import AgentProfile from "./AllPanels/AgentPannel/AgentProfile";
import BuilderProfile from "./AllPanels/BuilderPanel/BuilderProfile";
import BuilderHistory from "./AllPanels/BuilderPanel/BuilderHistory";
import AgentHistory from "./AllPanels/AgentPannel/AgentHistory";
import UserProfile from "./AllPanels/UserPannel/UserProfile";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/all-pages-demo" element={<AllPagesDemo />}/>
        <Route path="/propertyform" element={<PropertyForm />}/>
        <Route path="/post-your-property" element={<PostYourProperty />}/>
        <Route path="/post-your-property" element={<PropertyForm />}/>
        <Route path="/listed-property" element={<ListedProperty />}/>
        <Route path="/signup" element={<SignUp />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/agent-login" element={<AgentLogin />}/>
        <Route path="/agent-list" element={<AgentsList />}/>
        <Route path="/forgort-password-reqest" element={<ForgortPasswordReqest />}/>
        <Route path="/forgort-password" element={<ForgortPassword />}/>
        <Route path="/signupform" element={<SignUpForm />}/>
        <Route path="/update-detail" element={<UpdateDetail />}/>
        <Route path="/news-list" element={<News />}/>
        {/* Property pages */}
        <Route path="/property-details" element={<PropertyDetails />}/>
        <Route path="/property-details-single" element={<PropertyDetailsSingle />}/>
        <Route path="/add-property-video" element={<AddPropertyVideo />}/>
        {/* Event Pages */}
        <Route path="/event-list" element={<EventList />}/>
        <Route path="/event-details" element={<EventDetails />}/>
        <Route path="/event-registration" element={<EventRegistration />}/>
        {/* User Detail Pages */}
        <Route path="/user-details" element={<UserDetails />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/edit-user-details" element={<EditUserDetails/>}/>
        <Route path="/not-found" element={<UnderDev/>}/>
        <Route path="/find-agents" element={<FindAgents/>}/>
        <Route path="/find-builders" element={<FindBuilders/>}/>
        <Route path="/user-history" element={<UserHistory/>}/>
        <Route path="/find-property" element={<FindProperty/>}/>
      </Routes>
      <Routes>
        <Route path="/admin-dashboard" element={<AdminDashboard/>}/>
        <Route path="/admin-login" element={<AdminLogin/>}/>
        <Route path="/admin-forgort-password" element={<AdminForgortPassword/>}/>
        <Route path="/admin-forgort-password-reqest" element={<AdminForgortPasswordReqest/>}/>
        {/* Customer */}
        <Route path="/customers" element={<Customers/>}/>
        <Route path="/customers-single-details" element={<CustomerSingleDetails/>}/>
        <Route path="/customer-activity-table" element={<CustomerActivityTable/>}/>
        {/* Agents */}
        <Route path="/agents" element={<Agents/>}/>
        <Route path="/agents-single-details" element={<AgentsSingleDetails/>}/>
        <Route path="/agents-activity-table" element={<AgentsActivityTable/>}/>
        {/* Builder */}
        <Route path="/builder" element={<Builder/>}/>
        <Route path="/builder-single-details" element={<BuilderSingleDetails/>}/>
        <Route path="/builder-activity-table" element={<BuilderActivityTable/>}/>
        <Route path="/projects" element={<Projects/>}/>
        <Route path="/properties" element={<Properties/>}/>
        <Route path="/properties-details-single" element={<PropertiesDetailsSingle/>}/>
        <Route path="/events" element={<Events/>}/>
        <Route path="/events-details-single" element={<EventsDetailsSingle/>}/>
        <Route path="/builder-offerce" element={<BuilderOfferce/>}/>
      </Routes>

      {/* User Pannel Sidebr*/}
      <Routes>
        <Route path="/user-dashboard" element={<UserDashboard />}/>
        <Route path="/user-profile" element={<UserProfile />}/>
        
      </Routes>
      {/* Agent Pannel Sidebr*/}
      <Routes>
        <Route path="/agent-dashboard" element={<AgentDashboard/>}/>
        <Route path="/agent-event-list" element={<AgentEventList/>}/>
        <Route path="/agent-event-details" element={<AgentEventDetails/>}/>
        <Route path="/agent-listed-property" element={<AgentListedProperty/>}/>
        <Route path="/agent-Offerce" element={<AgentOfferce/>}/>
        <Route path="/agent-news" element={<AgentNews/>}/>
        <Route path="/agent-profile" element={<AgentProfile/>}/>
        <Route path="/agent-history" element={<AgentHistory/>}/>
      </Routes>

      {/* Builder Pannel Sidebr*/}
      <Routes>
        <Route path="/builder-dashboard" element={<BuilderDashboard/>}/>
        <Route path="/builder-listed-property" element={<BuilderListedProperty/>}/>
        <Route path="/builder-agent-list" element={<BuilderAgentList/>}/>
        <Route path="/builder-event-list" element={<BuilderEventList/>}/>
        <Route path="/builder-offerce" element={<BuilderOfferce/>}/>
        <Route path="/builder-profile" element={<BuilderProfile/>}/>
        <Route path="/builder-history" element={<BuilderHistory/>}/>
      </Routes>
    </>
  );
}

export default App;
