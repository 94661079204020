import React from "react";
import {
  Button,
  Container,
  Nav,
  Row,
  Col,
  Carousel,
  Card,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUp from "../SignUp";
const ForgortPassword = () => {
  return (
    <>
      <SignUp>
        <div className="px-3 px-md-4 px-lg-5 mx-0 mx-md-4 mx-lg-5 py-5 py-md-4 py-lg-0 signup-form">
          <Form>
            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-open fw-600">
                  Enter your one time password
                </h2>
                <p className="fs-16 fw-400 font-open text-dove-gray">
                  Start with your free account today
                </p>
              </Col>
              <Col xs={12} className="">
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col xs={12} className="d-grid">
                <Button size="lg" className="btn btn-primary submit-btn">
                  Verify
                </Button>
              </Col>
              <Col className="text-end mt-3">
                <Link>Resend OTP</Link>
              </Col>
            </Row>
          </Form>
        </div>
      </SignUp>
    </>
  );
};

export default ForgortPassword;
