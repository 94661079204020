import React from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
import FinanceSection from "./FinanceSection";
import ServiceCard from "./ServiceCard";

const Services = () => {
  return (
    <>
      <section>
        <Container fluid>
          <Row className="d-block d-lg-flex justify-content-center">
            <Col xs md={12} lg={6} className="p-0">
              <Image
                className="img-fluid vh-100"
                src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              />
            </Col>
            <Col
              xs
              md={12}
              lg={6}
              className="bg-dark px-sm-3 px-md-5 py-5 d-flex align-items-center"
            >
              <ServiceCard />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid>
          <Row className="d-block d-lg-flex justify-content-center mb-5">
            <Col
              xs
              md={12}
              lg={6}
              className="text-center bg-dark text-white px-sm-3 px-md-5 py-5 d-flex align-items-center"
            >
              <ServiceCard />
            </Col>
            <Col xs md={12} lg={6} className="p-0">
              <Image
                className="img-fluid vh-100"
                src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <FinanceSection />
      <section className="">
        <Container fluid>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={6} className="p-0">
              <Image
                className="img-fluid"
                src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              />
            </Col>
            <Col
              xs={12}
              md={12}
              lg={6}
              className="bg-royal-blue text-white p-5 d-flex align-items-center"
            >
              <div className="text-center">
                <h1 className="font-rubic text-white fw-500">Our Services</h1>
                <p className="fs-14 font-rubic fw-500 text-white">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae mollitia sed velit? Nisi accusamus provident nihil
                  cumque enim nam ea rem ex, dolor
                </p>
                <ul className="mb-3 text-start text-white fs-27 fw-500">
                  <li>Lorem ipsum dolor, sit amet consectetur</li>
                  <li>Lorem ipsum dolor, sit amet consectetur</li>
                  <li>Lorem ipsum dolor, sit amet consectetur</li>
                  <li>Lorem ipsum dolor, sit amet consectetur</li>
                  <li>Lorem ipsum dolor, sit amet consectetur</li>
                  <li>Lorem ipsum dolor, sit amet consectetur</li>
                </ul>
                <Button variant="primary" className="btn-primary-custom">
                  Know More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Services;
