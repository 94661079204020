import React from "react";
import Slider from "react-slick";
import { Card, Button, Container, Row, Col, Badge } from "react-bootstrap";
import event1 from "../assets/img/event1.png";
import event2 from "../assets/img/event2.png";
import event3 from "../assets/img/event3.png";
import event4 from "../assets/img/event4.png";
import { AiOutlineClockCircle } from "react-icons/ai";

const EventSLider = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  const EventsSLides = [
    {
      id: 1,
      title: "PROS AND CONS ON A COUNTRYSIDE HOUSE",
      url: event1,
      para: "Some quick example text to build on the card title and make up the bulk of the cards content.",
    },
    {
      id: 2,
      title: "PROS AND CONS ON A COUNTRYSIDE HOUSE",
      url: event2,
      para: "Some quick example text to build on the card title and make up the bulk of the cards content.",
    },
    {
      id: 3,
      title: "PROS AND CONS ON A COUNTRYSIDE HOUSE",
      url: event3,
      para: "Some quick example text to build on the card title and make up the bulk of the cards content.",
    },
    {
      id: 4,
      title: "PROS AND CONS ON A COUNTRYSIDE HOUSE",
      url: event4,
      para: "Some quick example text to build on the card title and make up the bulk of the cards content.",
    },
    {
      id: 2,
      title: "PROS AND CONS ON A COUNTRYSIDE HOUSE",
      url: event1,
      para: "Some quick example text to build on the card title and make up the bulk of the cards content.",
    },
  ];

  return (
    <>
      <section className="event-slider-sec py-5">
        <Container className="py-5">
          <Row>
            <Col md={7}>
              <h2 className="text-capitalize text-shark font-rubic fw-500">
                exploare event type
              </h2>
              <p className="mb-0 fs-15 font-rubic fw-400 text-silver-chalice">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eveniet, ipsa illo.
              </p>
            </Col>
            <Col md={5}></Col>
          </Row>
          <Row>
            <Col>
              <Slider {...settings} className="py-5">
                {EventsSLides.map((Item) => (
                  <div key={Item.id}>
                    <Card className="m-3 recomend-card">
                      <Card.Img
                        className="rounded-4"
                        variant="top"
                        src={Item.url}
                      />
                      <Card.Body className="py-4">
                        <p className="bg-amaranth text-capitalize fs-9 font-rubi fw-400 text-white d-inline-block py-1 px-2 rounded">
                          category
                        </p>
                        <Card.Title className="fs-19 fw-500 font-rubic text-code-gray2">
                          {Item.title}
                        </Card.Title>
                        <Card.Footer className="d-flex justify-content-between align-items-center bg-transparent p-0 pt-3 mt-3">
                          <div className="d-flex">
                            <AiOutlineClockCircle className="text-orange" />
                            <span className="ms-2 fs-12 fw-500 font-rubic text-code-gary3">
                              DECEMBER 28, 2018
                            </span>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="dark" className="btn-dark-custom">
                MORE Events
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EventSLider;
