import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopAgentCard from "../../component/TopAgentCard";
import { ShortVideoSLides, TopAgentSlides } from "../../data/Data";
import { Link } from "react-router-dom";
import BuilderDashBoardLayout from "../../layout/DashBoardLayouts/BuilderDashBoardLayout";

const BuilderAgentList = () => {
  return (
    <>
      <BuilderDashBoardLayout>
        <Container>
          <Row>
            <Col xs={12}>
              <h2 className="text-capitalize text-shark font-rubic fw-500">
                Top Agent
              </h2>
              <p className="mb-3 fs-15 font-rubic fw-400 text-silver-chalice">
                Lorem ipsum dolor sit rhr rurg igu fi
              </p>
            </Col>
            {TopAgentSlides.map((Item) => (
              <Col md={4} className="mb-3">
                <Link to="/agents-single-details">
                  <div key={Item.id}>
                    <TopAgentCard
                      ClassProperty=""
                      Thumbnel={Item.url}
                      title={Item.title}
                    />
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </BuilderDashBoardLayout>
    </>
  );
};

export default BuilderAgentList;
