import React from "react";
import { Card, Nav, Container, Row, Col } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { ImLinkedin2 } from "react-icons/im";
import { BsArrowUpRight } from "react-icons/bs";
import feture1 from "../assets/img/feture1.png";
import feture2 from "../assets/img/feture2.png";

const OurAgents = () => {
  const OurAgent = [
    {
      id: 1,
      url: feture1,
      title: "Real estate broker",
      AgentName: "Pranay Masulkar",
      mobile: "8624043380",
      emailId: "user@gmail.com",
    },
    {
      id: 2,
      url: feture2,
      title: "Real estate broker",
      AgentName: "sonuli Masulkar",
      mobile: "8624043380",
      emailId: "user@gmail.com",
    },
    {
      id: 3,
      url: feture1,
      title: "Real estate broker",
      AgentName: "Pranay Masulkar",
      mobile: "8624043380",
      emailId: "user@gmail.com",
    },
    {
      id: 4,
      url: feture2,
      title: "Real estate broker",
      AgentName: "sonuli Masulkar",
      mobile: "8624043380",
      emailId: "user@gmail.com",
    },
  ];
  return (
    <>
      <section className="bg-concrete pb-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-end pb-2">
            <Col md={8}>
              <h2 className="text-capitalize text-shark font-rubic fw-500">
                Featured Agents
              </h2>
              <p className="mb-0 fs-15 font-rubic fw-400 text-silver-chalice">
                Lorem ipsum dolor sit amet consectetur.
              </p>
            </Col>
            <Col md={4} className="text-end">
              <Nav.Link className="text-capitalize btn btn-outline-secondary d-inline-block px-4 py-1 rounded-pill btn-semore">
                see all projects <BsArrowUpRight />
              </Nav.Link>
            </Col>
          </Row>
          <Row className="pt-4 pb-5">
            {OurAgent.map((Item) => (
              <Col md={3} key={Item.id}>
                <Card className="mb-3 p-3 our-agent-card">
                  <Card.Img variant="top" src={Item.url} />
                  <Card.Body className="px-0">
                    <Card.Text className="fs-13 font-rubic fw-400 text-dusty-gary2 mb-1 text-capitalize">
                      {Item.title}
                    </Card.Text>
                    <Card.Title className="fs-27 fw-500 font-rubic text-code-gray3 mb-3 text-capitalize">
                      {Item.AgentName}
                    </Card.Title>
                    <Card.Text className="mb-1 font-rubic fs-13 text-code-gray2 mb-1">
                      {Item.mobile}
                    </Card.Text>
                    <Card.Text className="mb-1 font-rubic fs-13 text-code-gray2 mb-1 text-lowercase">
                      {Item.emailId}
                    </Card.Text>
                    <div>
                      <Nav defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li">
                          <Nav.Link href="/home">
                            <FaFacebookF />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="link-1">
                            <BsTwitter />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="link-2">
                            <ImLinkedin2 />
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurAgents;
