import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import AdminPannelDashBoardLayout from "../../../layout/DashBoardLayouts/AdminPannelDashBoardLayout";

const CustomerData = [
  {
    SrNo: 1,
    Customer_Id: 1,
    Title: "1BHK Apartment",
    Email_Id: "vickym@gmail.com",
    DateOfListing: "10/08/2021",
    Mobile_No: "8624043380",
    City: "Nagpur",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
  {
    SrNo: 2,
    Customer_Id: 2,
    Title: "1BHK Apartment",
    DateOfListing: "10/08/2021",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    City: "Nagpur",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
  {
    SrNo: 3,
    Customer_Id: 3,
    Title: "1BHK Apartment",
    DateOfListing: "10/08/2021",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    City: "Nagpur",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
  {
    SrNo: 4,
    Customer_Id: 4,
    Title: "1BHK Apartment",
    DateOfListing: "10/08/2021",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    City: "Nagpur",
    Location: "Nagpur",
    Name: "Pranay Masulkar",
  },
];
const Projects = () => {
  return (
    <>
      <AdminPannelDashBoardLayout>
        <Row>
          <Col xs={12}>
            <Card className="mb-4">
              <Card.Header>
                <h3 className="my-3">Projects</h3>
              </Card.Header>
              <Card.Body>
                <Table>
                  <thead>
                    <tr>
                      <td scope="col">Sr. No.</td>
                      <td scope="col">Prorety Id</td>
                      <td scope="col">Title</td>
                      <td scope="col">Date of llisting</td>
                      <td scope="col">Email Id</td>
                      <td scope="col">Mobile No.</td>
                      <td scope="col">city</td>
                      <td scope="col">Location</td>
                      <td scope="col">listed By</td>
                    </tr>
                  </thead>
                  <tbody>
                    {CustomerData.map((Item) => (
                      <tr key={Item.index}>
                        <td scope="row">{Item.SrNo}</td>
                        <td>{Item.Customer_Id}</td>
                        <td>{Item.Title}</td>
                        <td>{Item.DateOfListing}</td>
                        <td>{Item.Email_Id}</td>
                        <td>{Item.Mobile_No}</td>
                        <td>{Item.City}</td>
                        <td>{Item.Location}</td>
                        <td>{Item.Name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminPannelDashBoardLayout>
    </>
  );
};

export default Projects;
