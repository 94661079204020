import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

const AllPagesDemo = () => {
  return (
    <>
      <Nav className="flex-column">
        <Link className="nav-link" to="/dashboard">
          dashboard
        </Link>
        <Link className="nav-link" to="/propertyform">
          propertyform
        </Link>
        <Link className="nav-link" to="/post-your-property">
          post your property
        </Link>
        <Link className="nav-link" to="/signup">
          signup
        </Link>
        <Link className="nav-link" to="/login">
          login
        </Link>
        <Link className="nav-link" to="/agent-login">
          agent login
        </Link>
        <Link className="nav-link" to="/forgort-password-reqest">
          forgort password reqest
        </Link>
        <Link className="nav-link" to="/forgort-password">
          forgort password
        </Link>
        <Link className="nav-link" to="/signupform">
          signupform
        </Link>
        <Link className="nav-link" to="/update-detail">
          update detail
        </Link>
        {/* Property pages */}
        <Link className="nav-link" to="/property-details">
          property details
        </Link>
        <Link className="nav-link" to="/property-details-single">
          property details single
        </Link>
        <Link className="nav-link" to="/add-property-video">
          add property video
        </Link>
        {/* Event Pages */}
        <Link className="nav-link" to="/event-list">
          event list
        </Link>
        <Link className="nav-link" to="/event-details">
          event details
        </Link>
        <Link className="nav-link" to="/event-registration">
          event registration
        </Link>
        {/* User Detail Pages */}
        <Link className="nav-link" to="/user-details">
          user details
        </Link>
        <Link className="nav-link" to="/contact">
          contact
        </Link>
        <Link className="nav-link" to="/about">
          about
        </Link>
        <Link className="nav-link" to="/edit-user-details">
          edit user details
        </Link>
        <Link className="nav-link" to="/not-found">
          not found
        </Link>
        <Link className="nav-link" to="/find-agents">
          find agents
        </Link>
        <Link className="nav-link" to="/find-builders">
          find builders
        </Link>
        <Link className="nav-link" to="/user-history">
          user history
        </Link>
        <Link className="nav-link" to="/find-property">
          find property
        </Link>

        <Link className="nav-link" to="/admin-dashboard">
          admin dashboard
        </Link>
        <Link className="nav-link" to="/admin-login">
          admin login
        </Link>
        <Link className="nav-link" to="/admin-forgort-password">
          admin forgort password
        </Link>
        <Link className="nav-link" to="/admin-forgort-password-reqest">
          admin forgort password reqest
        </Link>
        {/* Customer */}
        <Link className="nav-link" to="/customers">
          customers
        </Link>
        <Link className="nav-link" to="/customers-single-details">
          customers single details
        </Link>
        <Link className="nav-link" to="/customer-activity-table">
          customer activity table
        </Link>
        {/* Agents */}
        <Link className="nav-link" to="/agents">
          agents
        </Link>
        <Link className="nav-link" to="/agents-single-details">
          agents single details
        </Link>
        <Link className="nav-link" to="/agents-activity-table">
          agents activity table
        </Link>
        {/* Builder */}
        <Link className="nav-link" to="/builder">
          builder
        </Link>
        <Link className="nav-link" to="/builder-single-details">
          builder single details
        </Link>
        <Link className="nav-link" to="/builder-activity-table">
          builder activity table
        </Link>
        <Link className="nav-link" to="/projects">
          projects
        </Link>
        <Link className="nav-link" to="/properties">
          properties
        </Link>
        <Link className="nav-link" to="/properties-details-single">
          properties details single
        </Link>
        <Link className="nav-link" to="/events">
          events
        </Link>
        <Link className="nav-link" to="/events-details-single">
          events details single
        </Link>

        {/* Agent Pannel Sidebr*/}

        <Link className="nav-link" to="/agent-dashboard">
          agent dashboard
        </Link>

        {/* Builder Pannel Sidebr*/}
        <Link className="nav-link" to="/builder-dashboard">
          builder dashboard
        </Link>
      </Nav>
    </>
  );
};

export default AllPagesDemo;
