import React from "react";
import MainSlider from "../../component/sliders/MainSlider";
import TopAgentCard from "../../component/TopAgentCard";
import UserDashBoardLayout from "../../layout/DashBoardLayouts/UserDashBoardLayout";
import { TopAgentsettings } from "../../data/SliderSettings";
import { TopAgentSlides } from "../../data/Data";
import { Col, Row } from "react-bootstrap";
const FindBuilders = () => {
  return (
    <>
      <UserDashBoardLayout>
        <Row>
          <Col xs={12}>
            <h2 className="text-capitalize text-shark font-rubic fw-500">
              Bookmarks Builders / Developers / Propmoters
            </h2>
          </Col>
          {TopAgentSlides.map((Item) => (
            <Col md={3}>
              <div key={Item.id}>
                <TopAgentCard
                  ClassProperty=""
                  Thumbnel={Item.url}
                  title={Item.title}
                />
              </div>
            </Col>
          ))}
        </Row>
        <MainSlider
          SliderSetting={TopAgentsettings}
          ClassProperty="py-5"
          SectionTitle="Featured Builders / Developers / Propmoters"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {TopAgentSlides.map((Item) => (
            <div key={Item.id}>
              <TopAgentCard
                ClassProperty=""
                Thumbnel={Item.url}
                title={Item.title}
              />
            </div>
          ))}
        </MainSlider>
        <MainSlider
          SliderSetting={TopAgentsettings}
          ClassProperty="py-5"
          SectionTitle="Top Developers"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {TopAgentSlides.map((Item) => (
            <div key={Item.id}>
              <TopAgentCard
                ClassProperty=""
                Thumbnel={Item.url}
                title={Item.title}
              />
            </div>
          ))}
        </MainSlider>
      </UserDashBoardLayout>
    </>
  );
};

export default FindBuilders;
