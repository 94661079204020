import React from "react";
import Slider from "react-slick";
import { Card, Nav, Container, Row, Col, Button } from "react-bootstrap";
import { BsArrowUpRight } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";

const ShotVideoCard = (props) => {
  return (
    <>
      <Card className="m-2 border-0">
        {/* <iframe width="100%" height="250" src={Item.url}></iframe> */}
        <img src={props.Thumbnel} alt="" />
        <Card.Body className="d-flex justify-content-between align-items-center px-0">
          <Card.Title className="fs-15 fw-500 font-open text-code-gray2 mb-0 ps-2">
            {props.title}
          </Card.Title>
          <Button
            variant="outline-primary"
            className="border-0 rounded-circle px-1 py-1"
          >
            <HiDotsVertical size={18} />
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default ShotVideoCard;
