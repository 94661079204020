import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import ContactEmail from "../../assets/img/contact-email.png";
import contactTeliphone from "../../assets/img/contact-teliphone.png";
import contactLocation from "../../assets/img/contact-location.png";
import HeroBanner from "../../component/HeroBanner";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <>
      <Header />
      <div className="header-divider"></div>
      <HeroBanner />
      <section className="py-5">
        <Container>
          <Row className="pt-5">
            <Col md={4}>
              <Card className="shadow-lg mb-5 py-5 px-2">
                <Card.Body className="text-center">
                  <Image src={ContactEmail} className="img=fluid" />
                  <h4 className="font-open text-capitalize text-shark fw-600 my-4">
                    Email Address
                  </h4>
                  <Link
                    to=""
                    className="d-block text-dove-gray text-decoration-none"
                  >
                    info@example.com
                  </Link>
                  <Link
                    to=""
                    className="d-block text-dove-gray text-decoration-none"
                  >
                    info@example.com
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-lg mb-5 py-5 px-2">
                <Card.Body className="text-center">
                  <Image src={contactTeliphone} className="img=fluid" />
                  <h4 className="font-open text-capitalize text-shark fw-600 my-4">
                    Phone Number
                  </h4>
                  <Link
                    to=""
                    className="d-block text-dove-gray text-decoration-none"
                  >
                    info@example.com
                  </Link>
                  <Link
                    to=""
                    className="d-block text-dove-gray text-decoration-none"
                  >
                    info@example.com
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-lg mb-5 py-5 px-2">
                <Card.Body className="text-center">
                  <Image src={contactLocation} className="img=fluid" />
                  <h4 className="font-open text-capitalize text-shark fw-600 my-4">
                    Email Address
                  </h4>
                  <Link
                    to=""
                    className="d-block text-dove-gray text-decoration-none"
                  >
                    info@example.com
                  </Link>
                  <Link
                    to=""
                    className="d-block text-dove-gray text-decoration-none"
                  >
                    info@example.com
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-blue-ribbon-2">
        <Container>
          <Row className="d-flex justify-content-center align-items-center py-5">
            <Col md={6} className="mb-4 mb-md-0">
              <ContactForm />
            </Col>
            <Col md={6}>
              <Card className="border-0">
                <Card.Body>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7445.862283025442!2d79.07357047404271!3d21.075412255079357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bf1d0c8c8d87%3A0x89586935cc0b66e8!2sBeltarodi%2C%20Nagpur%2C%20Maharashtra%20441108!5e0!3m2!1sen!2sin!4v1674211789417!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    className="border-0 m-0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-blue-ribbon py-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6}>
              <p className="bg-danger text-white d-inline-block fs-14 px-4 py-1 rounded">
                SUBSCRIBE
              </p>
              <h3 className="text-white">
                Sign Up To Our Newsletter To Get The Latest News And Offers.
              </h3>
            </Col>
            <Col md={6}>
              <InputGroup className="my-4">
                <Form.Control
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  className="rounded-0"
                />
                <Button
                  variant="warning"
                  className="px-3 px-md-5 rounded-0"
                  id="button-addon2"
                >
                  Subscribe Now
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
