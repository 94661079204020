import React from "react";
import sliderimg from "../assets/img/slider.png";
import { HiUserCircle } from "react-icons/hi";
import areaicon from "../assets/img/area-icon.png";
import { Card } from "react-bootstrap";
const VideoCard = () => {
  const Singlevvideo = [
    {
      id: 1,
      title: "Plot No. 15, 3rd Bus Stop, Gopaljb Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 2,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 3,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 4,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 5,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 6,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 7,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 8,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
  ];
  return (
    <>
      <Card className="recomend-card p-3 mb-3">
        <img src={sliderimg} alt="" />
        {/* <iframe width="100%" height="200" src={Item.url}></iframe> */}
        <Card.Body className="pt-4 pb-0 px-0">
          <Card.Title className="fs-18 fw-500 font-rubic text-code-gray3">
            Reference Lorem Ipsum,
          </Card.Title>
          <p className="mb-0 fs-16 text-capitalize fw-400 font-rubic text-dove-gray mb-0">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </p>
        </Card.Body>
      </Card>
    </>
  );
};

export default VideoCard;
