import React from "react";
import {
  Button,
  Container,
  Nav,
  Row,
  Col,
  Carousel,
  Card,
} from "react-bootstrap";
import Header from "../../Header";

const SignUp = (props) => {
  const { children } = props;

  return (
    <>
      <Header />
      <div className="header-divider"></div>
      <section className="">
        <Container fluid className="p-0">
          <Row className="d-flex justify-content-center align-items-stretch">
            <Col
              lg={{ span: 5, order: "first" }}
              md={{ order: "last" }}
              xs={{ order: "last" }}
              className="signiup-slider px-3 px-md-4 px-lg-5 pb-5"
            >
              <div className="">
                <Carousel controls={false} className="m-0 m-md-4 m-lg-5">
                  <Carousel.Item className="p-3">
                    <Carousel.Caption>
                      <Card className="mb-0 text-start p-3 border-0 shadow">
                        <Card.Img
                          variant="top"
                          src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        />
                        <Card.Body className="px-0">
                          <Card.Title className="fs-24 fw-700 font-open text-code-gray2">
                            Lorem ipsum dummy content
                          </Card.Title>
                          <Card.Text className="fs-16 fw-400 font-open text-dove-gray">
                            Some quick example text to build on the card title
                          </Card.Text>
                          <Nav
                            className="justify-content-between"
                            activeKey="/home"
                          >
                            <Nav.Item>
                              <Nav.Link
                                className="font-open fs-16 fw-400 text-river-bed"
                                href="/home"
                              >
                                3 beadroom
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="font-open fs-16 fw-400 text-river-bed"
                                eventKey="link-1"
                              >
                                3 bathroom
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="font-open fs-16 fw-400 text-river-bed"
                                eventKey="link-2"
                              >
                                3 garages
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <h4 className="font-open text-midnight fw-700 text-capitalize mt-2">
                            Rent Sale
                          </h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h5 className="text-blue-ribbon fs-25 font-open fw-700">
                                10000.00{" "}
                                <span className="fs-15 font-open fw-500 text-gary">
                                  / month
                                </span>
                              </h5>
                            </div>
                            <div>
                              <Button variant="primary" className="apply-btn">
                                apply now
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item className="p-3">
                    <Carousel.Caption>
                      <Card className="mb-0 text-start p-3 border-0 shadow">
                        <Card.Img
                          variant="top"
                          src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        />
                        <Card.Body className="px-0">
                          <Card.Title className="fs-24 fw-700 font-open text-code-gray2">
                            Lorem ipsum dummy content
                          </Card.Title>
                          <Card.Text className="fs-16 fw-400 font-open text-dove-gray">
                            Some quick example text to build on the card title
                          </Card.Text>
                          <Nav
                            className="justify-content-between"
                            activeKey="/home"
                          >
                            <Nav.Item>
                              <Nav.Link
                                className="font-open fs-16 fw-400 text-river-bed"
                                href="/home"
                              >
                                3 beadroom
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="font-open fs-16 fw-400 text-river-bed"
                                eventKey="link-1"
                              >
                                3 bathroom
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="font-open fs-16 fw-400 text-river-bed"
                                eventKey="link-2"
                              >
                                3 garages
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <h4 className="font-open text-midnight fw-700 text-capitalize mt-2">
                            Rent Sale
                          </h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h5 className="text-blue-ribbon fs-25 font-open fw-700">
                                10000.00{" "}
                                <span className="fs-15 font-open fw-500 text-gary">
                                  / month
                                </span>
                              </h5>
                            </div>
                            <div>
                              <Button variant="primary" className="apply-btn">
                                apply now
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
            <Col
              lg={{ span: 7, order: "last" }}
              md={{ order: "first" }}
              xs={{ order: "first" }}
              className="px-3 px-md-4 px-lg-5 d-flex justify-content-center align-items-center"
            >
              {children}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
