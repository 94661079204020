import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import UserDashBoardLayout from "../../../layout/DashBoardLayouts/UserDashBoardLayout";
import { BiCheck } from "react-icons/bi";
import { MdOutlineDateRange } from "react-icons/md";

const AddPropertyVideo = () => {
  return (
    <>
      <UserDashBoardLayout>
        <Container>
          <Row className="mb-2 d-flex align-items-center">
            <Col xs={12} md={8}>
              <h2 className="font-open fw-700 text-downriver text-capitalize">
                Add Video Of Your Property
              </h2>
              <p className="fs-15 font-open fw-400 text-code-gray">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
                nihil fugiat magni sunt nesciunt quam debitis.
              </p>
            </Col>
          </Row>
          <Row className="mb-2 d-flex align-items-center">
            <Col xs={12}>
              <h5 className="text-tundora fs-16 fw-700 font-open mb-3 text-capitalize">
                schedule
              </h5>
            </Col>
            <Col md={6}></Col>
            <Col md={3}>
              <Form.Group className="mb-3 me-1">
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Project No "
                />
              </Form.Group>
              <Form.Group className="mb-3 me-1">
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Land Mark"
                />
              </Form.Group>
              <Form.Group className="mb-3 me-1">
                <Form.Control className="" type="text" placeholder="RERA No." />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Project Address"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2 d-flex align-items-center">
            <Col>
              <h5 className="text-tundora fs-16 fw-700 font-open mb-3 text-capitalize">
                PROJECT TYPE
              </h5>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                residencial
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                cumercial
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                semi (cumercial)
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                farmHouse
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                Indevisual
              </Button>{" "}
            </Col>
          </Row>
          <Row className="mb-2 d-flex align-items-center">
            <Col>
              <h5 className="text-tundora fs-16 fw-700 font-open mb-3 text-capitalize">
                PROJECT Specification
              </h5>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                residencial
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                cumercial
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                semi (cumercial)
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                farmHouse
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                Indevisual
              </Button>{" "}
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col md={4} className="d-flex">
              <h6 className="text-emperor fs-16 fw-500 font-open mt-2 m-0 me-3">
                Passion
              </h6>
              <Form.Group>
                <Form.Control
                  size="lg"
                  className="rounded-pill"
                  type="date"
                  placeholder="DD/MM/YY"
                />
              </Form.Group>
            </Col>
            <Col md={5} className="d-flex">
              <h6 className="text-emperor fs-16 fw-500 font-open mt-2 m-0 me-3">
                Project launching date
              </h6>
              <Form.Group>
                <Form.Control
                  size="lg"
                  className="rounded-pill"
                  type="date"
                  placeholder="DD/MM/YY"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col md={4} className="d-flex">
              <h6 className="text-emperor fs-16 fw-500 font-open mt-2 m-0 me-3">
                price starting
              </h6>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                residencial
              </Button>{" "}
            </Col>
            <Col md={4} className="d-flex">
              <h6 className="text-emperor fs-16 fw-500 font-open mt-2 m-0 me-3">
                Min Sq. Area
              </h6>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                residencial
              </Button>{" "}
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col md={4} className="d-flex">
              <h6 className="text-emperor  fs-16 fw-500 font-open mt-2 m-0 me-3">
                Price Starting
              </h6>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                residencial
              </Button>{" "}
            </Col>
            <Col md={4} className="d-flex">
              <h6 className="text-emperor fs-16 fw-500 font-open mt-2 m-0 me-3">
                Min sq/ft Area
              </h6>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                residencial
              </Button>{" "}
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col className="d-flex">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                Amenities
              </p>
              {["checkbox"].map((type) => (
                <div
                  key={`default-${type}`}
                  className="mb-3 d-flex fs-15 text-code-gray text-capitalize"
                >
                  <Form.Check
                    type={type}
                    label="PLC"
                    id={`default-${type}`}
                    className="me-3"
                  />

                  <Form.Check
                    type={type}
                    label=" Car Parking"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Club Membership"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Stamp Duty (Register changes excluded)"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                </div>
              ))}
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col md={6} className="d-flex">
              <h6 className="text-emperor fs-16 fw-500 font-open mt-2 m-0 me-3">
                Near by location
              </h6>
              <Form.Group>
                <Form.Control
                  size="lg"
                  className="rounded-pill"
                  type="text"
                  placeholder="Enter"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col xs={12}>
              <h5 className="text-royal-blue fs-21 fw-600 font-open mb-3 text-capitalize">
                Appreciation Factory :-
              </h5>
            </Col>
            <Col xs={12} className="d-flex my-2">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-5">
                Site Visit Timing :
              </p>
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                <MdOutlineDateRange classNAme="text-royal-blue" /> Days
              </p>
              {["checkbox"].map((type) => (
                <div
                  key={`default-${type}`}
                  className="mb-3 d-flex fs-15 text-code-gray text-capitalize"
                >
                  <Form.Check
                    type={type}
                    label="Man"
                    id={`default-${type}`}
                    className="me-3"
                  />

                  <Form.Check
                    type={type}
                    label="Tue"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Wed"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Thus"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Fri"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="sun"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                </div>
              ))}
            </Col>
            <Col xs={12} className="d-flex my-2">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                Pick Up & Drop Facility :-
              </p>
              {["checkbox"].map((type) => (
                <div
                  key={`default-${type}`}
                  className="mb-3 d-flex fs-15 text-code-gray text-capitalize"
                >
                  <Form.Check
                    type={type}
                    label="yes"
                    id={`default-${type}`}
                    className="me-3"
                  />

                  <Form.Check
                    type={type}
                    label="no"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                </div>
              ))}
            </Col>
          </Row>
          <Row className="my-4 d-flex align-items-center">
            <Col className="d-flex">
              <h5 className="text-royal-blue fs-21 fw-600 font-open mb-2 mt-2 text-capitalize mb-3 me-4">
                Finance Available from :
              </h5>
              <Form.Group>
                <Form.Select className="mb-3 rounded-pill">
                  <option>SBI</option>
                  <option value="1">YBL</option>
                  <option value="2">SBI</option>
                  <option value="3">PBN</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col xs={12}>
              <Card className="border-0 px-3 shadow">
                <Card.Body>
                  <h5 className="text-emperor fs-18 fw-600 font-open mb-2 mt-2 text-capitalize mb-3 me-4">
                    Contact Details
                  </h5>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Form.Group className="mb-3" as={Col} md={6}>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Full Name"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md={6}>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Designation"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md={6}>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Email Id"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md={6}>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Contact Number"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md={6}>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="WhatsApp Link"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} md={6}>
                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Website"
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control as="textarea" rows={7} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-start">
                    <Col md={3}>
                      <Button
                        variant="primary"
                        size="lg"
                        className="submit-btn w-100 fs-20"
                      >
                        submit
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </UserDashBoardLayout>
    </>
  );
};

export default AddPropertyVideo;
