import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
// import Imgeurl from "https://i.pinimg.com/736x/a9/fe/fe/a9fefe0767b468591389c5d87eb581d0.jpg";
import BigImg from "../../assets/img/photo1.png";
import AgentDashBoardLayout from "../../layout/DashBoardLayouts/AgentDashBoardLayout";
const AgentNews = () => {
  return (
    <>
      <AgentDashBoardLayout>
        <section>
          <Container>
            <Row>
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-rubic fw-500 mb-3">
                  Top News
                </h2>
              </Col>
              <Col md={4}>
                <Card className="recomend-card p-3 mb-3">
                  <img src={BigImg} alt="" />
                  {/* <iframe width="100%" height="200" src={Item.url}></iframe> */}
                  <Card.Body className="pt-4 pb-0 px-0">
                    <Card.Title className="fs-18 fw-500 font-rubic text-code-gray3">
                      Reference Lorem Ipsum,
                    </Card.Title>
                    <p className="mb-0 fs-16 text-capitalize fw-400 font-rubic text-dove-gray mb-0">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="recomend-card p-3 mb-3">
                  <img src={BigImg} alt="" />
                  {/* <iframe width="100%" height="200" src={Item.url}></iframe> */}
                  <Card.Body className="pt-4 pb-0 px-0">
                    <Card.Title className="fs-18 fw-500 font-rubic text-code-gray3">
                      Reference Lorem Ipsum,
                    </Card.Title>
                    <p className="mb-0 fs-16 text-capitalize fw-400 font-rubic text-dove-gray mb-0">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="recomend-card p-3 mb-3">
                  <img src={BigImg} alt="" />
                  {/* <iframe width="100%" height="200" src={Item.url}></iframe> */}
                  <Card.Body className="pt-4 pb-0 px-0">
                    <Card.Title className="fs-18 fw-500 font-rubic text-code-gray3">
                      Reference Lorem Ipsum,
                    </Card.Title>
                    <p className="mb-0 fs-16 text-capitalize fw-400 font-rubic text-dove-gray mb-0">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </AgentDashBoardLayout>
    </>
  );
};

export default AgentNews;
