import React, { useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Button,
} from "react-bootstrap";
import { BsFillBellFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { HiPlusCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { TiThMenu } from "react-icons/ti";

const HeaderUserDashboard = ({
  toggle,
  handleToggle,
  sidebar,
  handleSidebar,
  usersidebar,
  handleUserSidebar,
}) => {
  return (
    <>
      <header className="header">
        <Navbar bg="light" expand="lg" className="py-3" fixed="top">
          <Container fluid>
            <Button
              variant="outline-primary"
              onClick={handleToggle}
              className="me-3 menu-btn p-0 border-0 d-none d-md-none d-lg-block"
            >
              {toggle ? (
                <RiMenuUnfoldFill size={25} />
              ) : (
                <RiMenuFoldFill size={25} />
              )}
            </Button>
            <Button
              variant="outline-primary"
              onClick={handleSidebar}
              className="me-3 menu-btn p-0 border-0 d-block d-md-block d-lg-none"
            >
              {sidebar ? <TiThMenu size={25} /> : <TiThMenu size={25} />}
            </Button>
            <Navbar.Brand href="/">Real Estate</Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <Button
              variant="outline-primary"
              onClick={handleUserSidebar}
              className="menu-btn p-0 border-0 d-block d-md-block d-lg-none"
            >
              {usersidebar ? (
                <FaUserCircle size={25} />
              ) : (
                <FaUserCircle size={25} />
              )}
            </Button>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown
                  className="header-dropdown"
                  title="Nagpur"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav className="ms-auto">
                <Link
                  to="/dashboard"
                  className="nav-link abtn-primary-custom me-3"
                >
                  <HiPlusCircle size={18} /> post property
                </Link>
                <Link
                  to="/post-your-property"
                  className="nav-link abtn-primary-custom1 ms-3"
                >
                  <HiPlusCircle /> post your requirment
                </Link>
                <Nav.Link href="#link" className="mx-3">
                  <BsFillBellFill />
                </Nav.Link>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="lite" id="dropdown-basic">
                      <FaUserCircle /> pranay
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Nav.Link href="#home" className=" rounded-0">
                  <FiSettings />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default HeaderUserDashboard;
