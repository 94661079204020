import React from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="py-5 bg-code-gray4">
        <Container>
          <Row className="py-3">
            <Col xs={12} md={6} lg={3} className="mb-5 mb-md-0 pe-3 pe-md-5">
              <p className="fs-14 font-rubic text-white">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo
                aliquam minima illum itaque eligendi facilis voluptatem totam
                cum asperiores tempora, ut quas qui praesentium incidunt impedit
                dolorum quidem ullam eum.
              </p>
            </Col>
            <Col xs={12} md={6} lg={3} className="mb-5 mb-md-0 ps-3 ps-md-5">
              <h6 className="fs-18 fw-700 font-rubic text-white text-capitalize">
                Useful Links
              </h6>
              <Nav className="flex-column">
                <Link to="/about" className="nav-link">
                  About
                </Link>
                <Nav.Link eventKey="#">partner</Nav.Link>
                <Link to="/contact" className="nav-link">
                  contact
                </Link>
              </Nav>
            </Col>
            <Col xs={12} md={6} lg={3} className="mb-5 mb-md-0">
              <h6 className="fs-18 fw-700 font-rubic text-white text-capitalize">
                help ?
              </h6>
              <Nav className="flex-column">
                <Nav.Link href="/home">faq</Nav.Link>
                <Nav.Link eventKey="#">tearm and condition</Nav.Link>
                <Nav.Link eventKey="#">policy</Nav.Link>
                <Nav.Link eventKey="#">privacy</Nav.Link>
              </Nav>
            </Col>
            <Col xs={12} md={6} lg={3} className="mb-5 mb-md-0">
              <h6 className="fs-18 fw-700 font-rubic text-white text-capitalize">
                address
              </h6>
              <Nav className="flex-column">
                <Nav.Link href="/home" className="no-hover">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </Nav.Link>
                <Nav.Link eventKey="#" className="text-lowercase">
                  www.realestate.com
                </Nav.Link>
                <Nav.Link eventKey="#">+81 00000 00000</Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
      <section className="bg-code-gray5 py-4">
        <Container>
          <Row>
            <Col className="text-center text-md-start">
              <p className="fs-14 text-white font-rubic text-capitalize my-2">
                © Copyright 2022
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
