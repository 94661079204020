import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserDashBoardLayout from "../../../layout/DashBoardLayouts/UserDashBoardLayout";
import OwnerCard from "./OwnerCard";
import {
  RecomedListedSliderSettings,
  ListedShortSliderSetting,
} from "../../../data/SliderSettings";
import MainSlider from "../../../component/sliders/MainSlider";
import { RecomedSlides, ShortVideoSLides } from "../../../data/Data";
import VideoCardDetailed from "../../../component/VideoCardDetailed";
import ShotVideoCard from "../../../component/ShortVideoCard";
const ListedProperty = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <UserDashBoardLayout>
        <Container>
          {/* New sction */}
          <Row>
            <Col md={8}>
              {/* New section */}
              <MainSlider
                SliderSetting={RecomedListedSliderSettings}
                ClassProperty="pb-5"
                SectionTitle="Properties"
                SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
              >
                {RecomedSlides.map((Item) => (
                  <div key={Item.id}>
                    <VideoCardDetailed
                      ClassProperty="m-1 recomend-card"
                      title={Item.title}
                      propertImage={Item.propertImage}
                      clientname={Item.clientname}
                      timeline={Item.timeline}
                    />
                  </div>
                ))}
              </MainSlider>
              <MainSlider
                SliderSetting={ListedShortSliderSetting}
                ClassProperty="py-5"
                SectionTitle="Short Video"
                SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
              >
                {ShortVideoSLides.map((Item) => (
                  <div key={Item.id}>
                    <ShotVideoCard
                      ClassProperty=""
                      Thumbnel={Item.url}
                      title={Item.title}
                    />
                  </div>
                ))}
              </MainSlider>
            </Col>
            <Col md={4}>
              <OwnerCard />
            </Col>
          </Row>
        </Container>
      </UserDashBoardLayout>
    </>
  );
};

export default ListedProperty;
