import React from "react";
import VideoCardDetailed from "../../component/VideoCardDetailed";
import UserDashBoardLayout from "../../layout/DashBoardLayouts/UserDashBoardLayout";
import MainSlider from "../../component/sliders/MainSlider";
import { RecomedSliderSettings } from "../../data/SliderSettings";
import { RecomedSlides } from "../../data/Data";
import Slider from "react-slick";
import { Col, Row } from "react-bootstrap";
import { UserDashBoardHero } from "../../data/SliderSettings";
import EnquryForm from "../../component/Home/EnquryForm";
import BigImg from "../../assets/img/photo1.png";

const EventList = () => {
  const UserDashBoardHeroContent = [
    {
      id: 1,
      title: "Pranay Masulkar",
      url: BigImg,
    },
    {
      id: 2,
      title: "Pranay Masulkar",
      url: BigImg,
    },
    {
      id: 3,
      title: "Pranay Masulkar",
      url: BigImg,
    },
    {
      id: 4,
      title: "Pranay Masulkar",
      url: BigImg,
    },
  ];
  return (
    <>
      <UserDashBoardLayout>
        <Row>
          <Col className="p-0 mb-4">
            <Slider {...UserDashBoardHero} className="py-3 mx-4">
              {UserDashBoardHeroContent.map((Item) => (
                <div key={Item.id}>
                  <div>
                    <img src={Item.url} alt="" />
                  </div>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
        <MainSlider
          SliderSetting={RecomedSliderSettings}
          ClassProperty="py-5"
          SectionTitle="Today Events"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {RecomedSlides.map((Item) => (
            <div key={Item.id}>
              <VideoCardDetailed
                ClassProperty="m-1 recomend-card"
                title={Item.title}
                propertImage={Item.propertImage}
                clientname={Item.clientname}
                timeline={Item.timeline}
                linkTo="/event-details"
              />
            </div>
          ))}
        </MainSlider>

        <MainSlider
          SliderSetting={RecomedSliderSettings}
          ClassProperty="py-5"
          SectionTitle="Upcoming Events"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {RecomedSlides.map((Item) => (
            <div key={Item.id}>
              <VideoCardDetailed
                ClassProperty="m-1 recomend-card"
                title={Item.title}
                propertImage={Item.propertImage}
                clientname={Item.clientname}
                timeline={Item.timeline}
              />
            </div>
          ))}
        </MainSlider>
        {/* <section className="py-5">
          <Container>
            <Row className="mb-4">
              <Col md={7}>
                <h2 className="text-capitalize">Event List</h2>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Eveniet, ipsa illo.
                </p>
              </Col>
            </Row>
            <Row>
              {EventsSLides.map((Item) => (
                <Col md={4} key={Item.Id}>
                  <VideoCardDetailed
                    title={Item.title}
                    propertImage={Item.url}
                    clientname={Item.clientname}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </section> */}
        <EnquryForm />
      </UserDashBoardLayout>
    </>
  );
};
export default EventList;
