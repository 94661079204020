import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavLink
            to="/user-dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            dashboard
          </NavLink>
          {/* <NavLink to="/propertyform" className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }>
            property
          </NavLink> */}

          <NavLink
            to="/property-details"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            property Details
          </NavLink>
          <NavLink
            to="/property-details-single"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            property Details Single
          </NavLink>
          <NavLink
            to="/event-list"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Event List
          </NavLink>
          {/* <NavLink to="/event-registration"  className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }>
            Event Registration
          </NavLink> */}
          <NavLink
            to="/add-property-video"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Add Property Video
          </NavLink>
          <NavLink
            to="/user-details"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            User Details Page
          </NavLink>
          <NavLink
            to="/user-history"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            user history
          </NavLink>
          {/* <NavLink to="/contact"  className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }>
            contact
          </NavLink> */}
          {/* <NavLink to="/about"  className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }>
            about
          </NavLink> */}
          <NavLink
            to="/agent-login"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            agent login
          </NavLink>
          <NavLink
            to="/admin-dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Admin Dasboard
          </NavLink>
        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  );
};

export default Sidebar;
