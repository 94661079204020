import React from "react";
import {
  Button,
  Container,
  Nav,
  Row,
  Col,
  Carousel,
  Card,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUp from "../SignUp";
const ForgortPasswordReqest = () => {
  return (
    <>
      <SignUp>
        <div className="px-3 px-md-4 px-lg-5 mx-0 mx-md-4 mx-lg-5 py-5 py-md-4 py-lg-0 signup-form">
          <Form>
            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-open fw-600">
                  forgot password
                </h2>
                <p className="fs-16 fw-400 font-open text-dove-gray">
                  Start with your free account today
                </p>
              </Col>
              <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="email"
                  placeholder="Enter email / Mobile No."
                />
              </Form.Group>
              <Col xs={12} className="d-grid">
                <Link
                  to="/forgort-password"
                  size="lg"
                  className="btn btn-primary submit-btn"
                >
                  Send Code
                </Link>
              </Col>
            </Row>
          </Form>
        </div>
      </SignUp>
    </>
  );
};

export default ForgortPasswordReqest;
