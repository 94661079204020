import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import HeroBanner from "../../../component/HeroBanner";
import Footer from "../../../layout/Footer";
import Header from "../../../layout/Header";
import aboutCompany from "../../../assets/img/about-company.png";
import aboutBuildoing from "../../../assets/img/about-building.png";
const About = () => {
  return (
    <>
      <Header />
      <div className="header-divider"></div>
      <HeroBanner />
      <section className="d-flex justify-content-center align-items-center py-5 bg-gallery">
        <Container>
          <Row className="d-flex justify-content-center align-items-center py-0 py-md-5">
            <Col className="text-center" md={9}>
              <h1 className="text-royal-blue-dark font-open fw-700 display-5 mb-4 mb-4">
                Finding a home in the city
              </h1>
              <p className="fs-16 font-open text-black fw-400">
                Our home-solutions firm, Nestaway, was established in Bengaluru
                by Amarendra Sahu, Deepak Dhar, Jitendra Jagadev and Smruti
                Parida in January 2015. All four founders had experienced a
                common problem – finding a home in the big city. Young people
                faced different kinds of discrimination. Single women and
                bachelors are considered unreliable. Migrants from other places
                are viewed with suspicion as they appear as ‘foreigners’ in a
                new city. Further, many newcomers did not have the knowhow to
                get around, lacking access to local insights in a new city.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center pt-0 pt-md-5">
            <Col md={6} className="text-center">
              <Image src={aboutCompany} className="img-fluid w-50" />
            </Col>
            <Col md={6}>
              <h2 className="text-royal-blue-dark font-open fw-700 display-5 mb-4">
                Our Company
              </h2>
              <p className="fs-16 font-open text-dove-gray fw-400">
                Our home-solutions firm, Nestaway, was established in Bengaluru
                by Amarendra Sahu, Deepak Dhar, Jitendra Jagadev and Smruti
                Parida in January 2015. All four founders had experienced a
                common problem – finding a home in the big city.
              </p>
              <p className="fs-16 font-open text-dove-gray fw-400">
                Young people faced different kinds of discrimination. Single
                women and bachelors are considered unreliable. Migrants from
                other places are viewed with suspicion as they appear as
                ‘foreigners’ in a new city. Further, many newcomers did not have
                the knowhow to get around, lacking access to local insights in a
                new city.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 about-vision">
        <Container>
          <Row className="d-flex justify-content-center align-items-center py-5">
            <Col md={6}>
              <h2 className="font-open text-black fw-700 display-5 mb-4">
                Vision
              </h2>
              <p className="fs-16 font-open text-dove-gray fw-400">
                Our home-solutions firm, Nestaway, was established in Bengaluru
                by Amarendra Sahu, Deepak Dhar, Jitendra Jagadev and Smruti
                Parida in January 2015. All four founders had experienced a{" "}
              </p>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </section>
      <section className="about-mission py-5">
        <Container>
          <Row className="d-flex justify-content-end align-content-center py-0 py-md-5 my-0 my-md-5">
            <Col md={6}></Col>
            <Col md={6}>
              <h2 className="font-open text-white fw-700 display-5 mb-4">
                Mission
              </h2>
              <p className="fs-16 font-open text-white fw-400">
                Our home-solutions firm, Nestaway, was established in Bengaluru
                by Amarendra Sahu, Deepak Dhar, Jitendra Jagadev and Smruti
                Parida in January 2015. All four founders had experienced a
              </p>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </section>
      <section className="about-building py-5">
        <Container className="py-0 py-md-5">
          <Row className="d-flex justify-content-start align-items-center">
            <Col xs={12}>
              <h2 className="font-open text-black fw-700 display-5 mb-4">
                BUILDING A WORLD OF EXCELLENCE
              </h2>
            </Col>
            <Col md={5}>
              <p className="fs-16 font-open text-dove-gray fw-400 mb-0">
                Our home-solutions firm, Nestaway, was established in Bengaluru
                by Amarendra Sahu, Deepak Dhar, Jitendra Jagadev and Smruti by
                Amarendra Sahu, Deepak Dhar, Jitendra Jagadev and Smruti Parida
                in January 2015. All four founders had experienced a Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Ab doloribus ullam
                praesentium numquam nobis minus aperiam. Fuga reprehenderit
                neque saepe. Deserunt molestias magnam cum repudiandae mollitia
                eum voluptates ab at!
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={8}>
              <Image src={aboutBuildoing} className="img-fluid building-img" />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default About;
