import React from "react";
import sliderimg from "../assets/img/slider.png";
import { HiUserCircle } from "react-icons/hi";
import areaicon from "../assets/img/area-icon.png";
import { Card, Button } from "react-bootstrap";
import carpark from "../assets/img/carpark-icon.png";
import battub from "../assets/img/bathtub-icon.png";
import bed from "../assets/img/bed.png";
import { Link } from "react-router-dom";
const VideoCardDetailed = (props) => {
  const Singlevvideo = [
    {
      id: 1,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 2,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 3,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 4,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 5,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
    {
      id: 6,
      title: "Plot No. 15, 3rd Bus Stop, Gopal Nagar, Nagpur - 440022",
      url: sliderimg,
      para: "Some quick example text to build on the card title and",
    },
  ];
  return (
    <>
      <Link to={props.linkTo} className="text-decoration-underline-none">
        <Card className={props.ClassProperty}>
          <img src={props.propertImage} alt="" />
          {/* <iframe width="100%" height="200" src={Item.url}></iframe> */}
          <Card.Body className="py-4">
            <Card.Title className="fs-14 fw-400 font-rubic text-code-gray2">
              {props.title}
            </Card.Title>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="mb-0 fs-10 text-capitalize fw-400 font-rubic text-code-gray">
                <HiUserCircle size={15} />{" "}
                <span className="text-royal-blue">{props.clientname}</span>
              </p>
              <p className="mb-0 fs-9 text-capitalize fw-400 font-rubic text-dove-gray">
                {props.timeline}
              </p>
            </div>
            <Card.Footer className="d-flex justify-content-between align-items-center bg-transparent p-0 pt-3 mt-3">
              <div className="d-flex">
                <img src={areaicon} className="" alt="" />{" "}
                <span className="ms-2 fs-12 fw-500 font-rubic text-code-gary3">
                  2400
                </span>{" "}
                <span className="ms-1 fs-12 fw-400 font-rubic text-code-gary3">
                  Sq Ft{" "}
                </span>
              </div>
              <div className="d-flex justify-content-cente align-items-centerr">
                <Button
                  variant="primary"
                  className="d-flex align-items-center ms-2 border-0 p-0 bg-transparent"
                >
                  <img src={carpark} className="" alt="" />{" "}
                  <span className="ms-2 fs-12 font-rubic fw-500 text-code-gray3">
                    {" "}
                    3
                  </span>
                </Button>{" "}
                <Button
                  variant="primary"
                  className="d-flex align-items-center ms-2 ms-md-2 border-0 p-0 bg-transparent"
                >
                  <img src={battub} alt="" />{" "}
                  <span className="ms-2 fs-12 font-rubic fw-500 text-code-gray3">
                    {" "}
                    3
                  </span>
                </Button>{" "}
                <Button
                  variant="primary"
                  className="d-flex align-items-center ms-2 ms-md-2 border-0 p-0 bg-transparent"
                >
                  <img src={bed} alt="" />{" "}
                  <span className="ms-2 fs-12 font-rubic fw-500 text-code-gray3">
                    {" "}
                    3
                  </span>
                </Button>{" "}
              </div>
            </Card.Footer>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
};

export default VideoCardDetailed;
