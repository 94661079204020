import React from "react";
import UserDashBoardLayout from "../../layout/DashBoardLayouts/UserDashBoardLayout";

const UnderDev = () => {
  return (
    <>
      <UserDashBoardLayout>
        <h1>Under Development</h1>
      </UserDashBoardLayout>
    </>
  );
};

export default UnderDev;
