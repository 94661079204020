import React from "react";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";

const BottomBar = () => {
  return (
    <>
      <section className="bg-white pt-4 pb-2 rounded-0 px-3 mt-5">
        <Container>
          <Row>
            <Col>
              <p className="text-tundora fw-400 font-open fs-14 text-capitalize">
                2022 © Real Estate.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BottomBar;
