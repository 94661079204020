import React from "react";
import { Carousel } from "react-bootstrap";
import Bgimage from "../../src/assets/img/photo1.png";
const HeroSlider = () => {
  return (
    <>
      <section className="main-hero-slider">
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={Bgimage} alt="First slide" />
            <Carousel.Caption>
              <div>
                {/* <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/1hLBCOlptq8"
                ></iframe> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Bgimage} alt="First slide" />
            <Carousel.Caption>
              <div>
                {/* <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/1hLBCOlptq8"
                ></iframe> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default HeroSlider;
