import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const SidebarAdminPannel = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavLink
            to="/admin-dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Admin Dashboard
          </NavLink>
          <NavLink
            to="/customers"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Customer
          </NavLink>
          <NavLink
            to="/builder"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            builder
          </NavLink>
          <NavLink
            to="/agents"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Agents
          </NavLink>
          <NavLink
            to="/properties"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            properties
          </NavLink>
          <NavLink
            to="/projects"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            projects
          </NavLink>
          <NavLink
            to="/events"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            events
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            log Out
          </NavLink>
        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  );
};

export default SidebarAdminPannel;
