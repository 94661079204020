import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const AdminAuthLayout = (props) => {
  const { children } = props;
  return (
    <>
      <section className="adminauth vh-100 w-100">
        <Container fluid className="p-0">
          <Row className="vh-100 d-flex justify-content-center align-items-stretch">
            <Col
              md={6}
              className="px-3 px-md-4 px-lg-5 d-flex justify-content-center align-items-center"
            >
              {children}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AdminAuthLayout;
