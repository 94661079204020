import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import HeroBaneer from "../assets/img/contact-header.png";

const HeroBanner = () => {
  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row>
            <Col className="p-0">
              <Image src={HeroBaneer} className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
