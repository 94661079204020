import React from "react";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";

const TopBar = () => {
  return (
    <>
      <section className="bg-White-Lilac2 pt-4 pb-2 border-bottom rounded-0 px-3 mb-5">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <h3 className="text-tundora fw-500 font-open fs-18 text-capitalize mb-3 mb-md-0">
                Dashboard
              </h3>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-start justify-content-md-end"
            >
              <Breadcrumb className="breadcrumb-topbar fs-14 font-open fw-500">
                <Breadcrumb.Item active href="#" className="">
                  Post Your Property
                </Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TopBar;
