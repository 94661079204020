import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsFillCircleFill } from "react-icons/bs";

const SidebarBuilder = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <Nav className="flex-column">
          <NavLink
            to="/builder-dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Builder Dashboard
          </NavLink>
          <h6 className="p-3 mb-0 bg-si">Properties</h6>
          <NavLink
            to="/builder-listed-property"
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive
                ? "nav-link ms-2 active"
                : "nav-link ms-2"
            }
          >
            <BsFillCircleFill size={8} className="me-1" /> Listed Properties
          </NavLink>
          <NavLink
            to="/post-your-property"
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive
                ? "nav-link ms-2 active"
                : "nav-link ms-2"
            }
          >
            <BsFillCircleFill size={8} className="me-1" /> Post Your Properties
          </NavLink>
          <h6 className="p-3 mb-0 bg-si">Projects</h6>
          <NavLink
            to="/builder-listed-property"
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive
                ? "nav-link ms-2 active"
                : "nav-link ms-2"
            }
          >
            <BsFillCircleFill size={8} className="me-1" /> Listed Projects
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive
                ? "nav-link ms-2 active"
                : "nav-link ms-2"
            }
          >
            <BsFillCircleFill size={8} className="me-1" /> Create Your Projects
          </NavLink>
          <h6 className="p-3 mb-0 bg-si">Events</h6>
          <NavLink
            to="/builder-event-list"
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive
                ? "nav-link ms-2 active"
                : "nav-link ms-2"
            }
          >
            <BsFillCircleFill size={8} className="me-1" /> Listed Events
          </NavLink>
          <NavLink
            to="/event-registration"
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive
                ? "nav-link ms-2 active"
                : "nav-link ms-2"
            }
          >
            <BsFillCircleFill size={8} className="me-1" /> Create Your Event
          </NavLink>
          <NavLink
            to="/builder-agent-list"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            agent list
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Home Loan
          </NavLink>
          <NavLink
            to="/builder-history"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            LogData/History
          </NavLink>
          <NavLink
            to="/builder-offerce"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Packages
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Support
          </NavLink>
          <NavLink
            to="/builder-profile"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            profiles
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            sign out
          </NavLink>
        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  );
};

export default SidebarBuilder;
