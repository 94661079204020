import React from "react";
import { RecomedSlides, ShortVideoSLides } from "../../data/Data";
import VideoCardDetailed from "../../component/VideoCardDetailed";
import { Row, Col } from "react-bootstrap";
import MainSlider from "../../component/sliders/MainSlider";
import ShotVideoCard from "../../component/ShortVideoCard";
import {
  DashboardShortSliderSetting,
  TopAgentsettings,
  RecomedSliderSettings,
} from "../../data/SliderSettings";
import { TopAgentSlides } from "../../data/Data";
import TopAgentCard from "../../component/TopAgentCard";
import AgentDashBoardLayout from "../../layout/DashBoardLayouts/AgentDashBoardLayout";
const AgentHistory = () => {
  return (
    <>
      <AgentDashBoardLayout>
        <section className="filters pb-5">
          <Row>
            <Col xs={12}>
              <h2 className="text-capitalize text-shark font-rubic fw-500">
                properties / projects
              </h2>
            </Col>
            {RecomedSlides.map((Item) => (
              <Col className="mt-3">
                <div key={Item.id}>
                  <VideoCardDetailed
                    ClassProperty="m-1 recomend-card"
                    title={Item.title}
                    propertImage={Item.propertImage}
                    clientname={Item.clientname}
                    timeline={Item.timeline}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </section>
        <MainSlider
          SliderSetting={DashboardShortSliderSetting}
          ClassProperty="py-5"
          SectionTitle="Property Shorts"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {ShortVideoSLides.map((Item) => (
            <div key={Item.id}>
              <ShotVideoCard
                ClassProperty=""
                Thumbnel={Item.url}
                title={Item.title}
              />
            </div>
          ))}
        </MainSlider>

        <MainSlider
          SliderSetting={TopAgentsettings}
          ClassProperty="py-5"
          SectionTitle="Top Agents"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {TopAgentSlides.map((Item) => (
            <div key={Item.id}>
              <TopAgentCard
                ClassProperty=""
                Thumbnel={Item.url}
                title={Item.title}
              />
            </div>
          ))}
        </MainSlider>
        <MainSlider
          SliderSetting={RecomedSliderSettings}
          ClassProperty="py-5"
          SectionTitle="Top Developers projects"
          SectionSubTitle="
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eveniet, ipsa illo."
        >
          {RecomedSlides.map((Item) => (
            <div key={Item.id}>
              <VideoCardDetailed
                ClassProperty="m-1 recomend-card"
                title={Item.title}
                propertImage={Item.propertImage}
                clientname={Item.clientname}
                timeline={Item.timeline}
              />
            </div>
          ))}
        </MainSlider>
      </AgentDashBoardLayout>
    </>
  );
};

export default AgentHistory;
