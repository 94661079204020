import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import BuilderDashBoardLayout from "../../layout/DashBoardLayouts/BuilderDashBoardLayout";

const BuilderOfferce = () => {
  return (
    <>
      <BuilderDashBoardLayout>
        <section>
          <Container>
            <Row>
              <Col md={4}>
                <Card className="shadow mb-3 p-2">
                  <Card.Body className="d-flex justify-content-center align-items-center py-5">
                    <h1 className="text-center py-5">Wellcome Offerce</h1>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="shadow mb-3 p-2">
                  <Card.Body className="d-flex justify-content-center align-items-center py-5">
                    <h1 className="text-center py-5">Wellcom e O fferce</h1>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="shadow mb-3 p-2">
                  <Card.Body className="d-flex justify-content-center align-items-center py-5">
                    <h1 className="text-center py-5">Wellcome Offerce</h1>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </BuilderDashBoardLayout>
    </>
  );
};

export default BuilderOfferce;
