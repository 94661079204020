import React from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import SignUp from "./SignUp";
import { BiCheck } from "react-icons/bi";
import { Link } from "react-router-dom";
const UpdateDetail = () => {
  return (
    <>
      <SignUp>
        <section className="px-5">
          <Container className="py-5">
            <Row>
              <Col>
                <h3 className="text-tundora fw-500 font-open fs-22">
                  I am Pranay Masulkar
                </h3>
                <p className="fs-15 font-open fw-400 text-gray2 mb-3 mb-md-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Facilis quod praesentium nulla ratione, deleniti perferend
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={5} className="pe-3 pe-md-4">
                <Row>
                  <Form.Group as={Col} md={12} className="mb-3">
                    <Form.Control type="text" placeholder="mobile no" />
                  </Form.Group>
                  <Form.Group as={Col} md={12} className="mb-3">
                    <Form.Control type="email" placeholder="email id" />
                  </Form.Group>
                </Row>
              </Col>
              <Col md={7} className="ps-s ps-md-5">
                <div className="d-flex justify-content-center align-items-center">
                  <p className="font-open fs-12 fw-600 text-uppercase text-dove-gray me-3">
                    otp
                  </p>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <p className="font-open fs-15 fw-500 text-royal-blue text-capitalize ms-3">
                    Verifyed
                  </p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <p className="font-open fs-12 fw-600 text-uppercase text-dove-gray me-3">
                    otp
                  </p>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="0"
                    />
                  </Form.Group>
                  <p className="font-open fs-15 fw-500 text-royal-blue text-capitalize ms-3">
                    Verifyed
                  </p>
                </div>
              </Col>
              <Col xs={12} className="pe-3 pe-md-5">
                <Row className="pe-0 pe-md-4">
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Select aria-label="Default select example">
                      <option>City</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Control type="text" placeholder="RERA No." />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12} className="d-flex my-4">
                <h3 className="text-tundora fw-500 font-open fs-22">
                  Advance Details
                </h3>
                <p className="fs-15 font-open fw-400 text-gray2 mb-0">
                  (Optional)
                </p>
              </Col>
              <Col md={3}>
                <p className="text-tundora fs-16 fw-500 font-open text-capitalize">
                  specality market
                </p>
              </Col>
              <Col>
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  sale
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  resale
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  rent
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  lease
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  comercial
                </Button>{" "}
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mb-3 ">
                <p className="text-tundora fs-16 fw-500 font-open text-capitalize">
                  years of experiance
                </p>
              </Col>
              <Form.Group as={Col} md={4} className="mb-3">
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Col md={6} className="mb-3 ">
                <p className="text-tundora fs-16 fw-500 font-open text-capitalize ">
                  any real estate certificate/diploma
                </p>
              </Col>
              <Form.Group as={Col} md={4} className="mb-3">
                <Form.Control type="text" placeholder="describe here" />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="text-tundora fs-16 fw-500 font-open text-capitalize">
                    describe your working style
                  </Form.Label>
                  <Form.Control as="textarea" rows={5} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs={12} md={6} className="text-center">
                <Link
                  to="/dashboard"
                  size="lg"
                  className="w-100 btn btn-primary submit-btn py-3 fs-23 font-open fw-600 text-uppercase"
                >
                  submit
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </SignUp>
    </>
  );
};

export default UpdateDetail;
