import React from "react";
import AdminAuthLayout from "./AdminAuthLayout";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminLogin = () => {
  return (
    <>
      <AdminAuthLayout>
        <div className="px-3 px-md-4 px-lg-5 mx-0 mx-md-4 mx-lg-5 py-5 signup-form bg-white rounded">
          <Form>
            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-open fw-600 mb-4">
                  Admin login
                </h2>
              </Col>
              <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} xs={12}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
              <Col xs={12} className="d-grid">
                <Link
                  to="/admin-dashboard"
                  size="lg"
                  className="btn btn-primary submit-btn"
                >
                  Log In
                </Link>
              </Col>
            </Row>
            <Col xs={12} className="d-flex justify-content-between">
              <Link to="/admin-forgort-password-reqest">Forgot Password</Link>
            </Col>
          </Form>
        </div>
      </AdminAuthLayout>
    </>
  );
};

export default AdminLogin;
