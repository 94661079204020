import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUp from "./SignUp";

const SignUpForm = () => {
  return (
    <>
      <SignUp>
        <div className="px-3 px-md-4 px-lg-5 mx-0 mx-md-4 mx-lg-5 py-5 py-md-4 py-lg-0 signup-form">
          <Form>
            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="text-capitalize text-shark font-open fw-600">
                  Sign Up
                </h2>
                <p className="fs-16 fw-400 font-open text-dove-gray">
                  Start with your free account today
                </p>
              </Col>
              <Col xs={12} className="d-block d-md-flex my-2">
                <span className="pe-3 fs-14 font-open fw-500 mb-2 d-inline-block">
                  I am{" "}
                </span>
                {["radio"].map((type) => (
                  <div
                    key={`inline-${type}`}
                    className="mb-3 fs-13 font-open fw-400"
                  >
                    <Form.Check
                      inline
                      label="Buyer"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label="Agent"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      inline
                      label="Buikder/Developer"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      inline
                      label="Owner"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      inline
                      label="Promotor"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </Col>
              <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="text"
                  placeholder="First Name"
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="text"
                  placeholder="Last Name"
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="text"
                  placeholder="Mobile No."
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
              <Form.Group className="mb-3 mb-md-4" as={Col} md={6}>
                <Form.Control
                  size="lg"
                  className="sign-input"
                  type="password"
                  placeholder="Conform Password"
                />
              </Form.Group>
              <Col xs={12} className="d-grid">
                <Link
                  to="/update-detail"
                  size="lg"
                  className="btn btn-primary submit-btn"
                >
                  submit
                </Link>
              </Col>
            </Row>
          </Form>
        </div>
      </SignUp>
    </>
  );
};

export default SignUpForm;
