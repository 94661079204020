import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminPannelDashBoardLayout from "../../../../layout/DashBoardLayouts/AdminPannelDashBoardLayout";

const CustomerData = [
  {
    SrNo: 1,
    Customer_Id: 1234,
    Name: "Pranay Masulkar",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    Location: "Nagpur",
  },
  {
    SrNo: 2,
    Customer_Id: 2234,
    Name: "Pranay Masulkar",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    Location: "Nagpur",
  },
  {
    SrNo: 3,
    Customer_Id: 3234,
    Name: "Pranay Masulkar",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    Location: "Nagpur",
  },
  {
    SrNo: 4,
    Customer_Id: 4234,
    Name: "Pranay Masulkar",
    Email_Id: "vickym@gmail.com",
    Mobile_No: "8624043380",
    Location: "Nagpur",
  },
];
const Customers = () => {
  return (
    <>
      <AdminPannelDashBoardLayout>
        <Row>
          <Col xs={12}>
            <Card className="mb-4">
              <Card.Header>
                <h3 className="my-3">Customer (Buyer/Tenet) List</h3>
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <td scope="col">Sr. No.</td>
                      <td scope="col">Customer Id</td>
                      <td scope="col">Name</td>
                      <td scope="col">Email Id</td>
                      <td scope="col">Mobile No.</td>
                      <td scope="col">Location</td>
                    </tr>
                  </thead>
                  <tbody>
                    {CustomerData.map((Item) => (
                      <tr key={Item.index} className="fw-400">
                        <td scope="row">{Item.SrNo}</td>
                        <td>
                          <Link to="/customers-single-details">
                            {Item.Customer_Id}
                          </Link>
                        </td>
                        <td>{Item.Name}</td>
                        <td>{Item.Email_Id}</td>
                        <td>{Item.Mobile_No}</td>
                        <td>{Item.Location}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminPannelDashBoardLayout>
    </>
  );
};

export default Customers;
