import React from "react";
import UserDashBoardLayout from "../../../layout/DashBoardLayouts/UserDashBoardLayout";

const EditUserDetails = () => {
  return (
    <>
      <UserDashBoardLayout>
        <h2>Etit User detail page</h2>
      </UserDashBoardLayout>
    </>
  );
};

export default EditUserDetails;
