import React from "react";
import { Col, Container, Row, Button, Form, InputGroup } from "react-bootstrap";
import UserDashBoardLayout from "../../../layout/DashBoardLayouts/UserDashBoardLayout";
import { BiCheck } from "react-icons/bi";

const PostYourProperty = () => {
  return (
    <>
      <UserDashBoardLayout>
        <Container>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex justify-content-start align-items-start">
              <div>
                <p className="text-tundora fs-21 fw-500 font-open mb-2 me-3">
                  I am
                </p>
              </div>
              <div>
                <Button variant="outline-secondary" className="btn-check-arrow">
                  {" "}
                  <BiCheck className="active-check" />
                  owner
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  {" "}
                  <BiCheck className="active-check" />
                  builder
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  {" "}
                  <BiCheck className="active-check" />
                  agency
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  {" "}
                  <BiCheck className="active-check" />
                  flatemate/roommate
                </Button>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={7} className="pe-3 pe-md-5">
              <Row>
                <Form.Group as={Col} md={5} className="mb-3">
                  <Form.Control type="text" placeholder="fisrt name" />
                </Form.Group>
                <Form.Group as={Col} md={5} className="mb-3">
                  <Form.Control type="text" placeholder="last name" />
                </Form.Group>
              </Row>
              <Row>
                <Col md={5}>
                  <Row>
                    <Form.Group as={Col} md={12} className="mb-3">
                      <Form.Control type="text" placeholder="mobile no" />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className="mb-3">
                      <Form.Control type="email" placeholder="email id" />
                    </Form.Group>
                  </Row>
                </Col>
                <Col>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="font-open fs-15 fw-500 text-royal-blue text-capitalize me-3">
                      Verifyed
                    </p>
                    <p className="font-open fs-12 fw-600 text-uppercase text-dove-gray me-3">
                      otp
                    </p>
                    <Form.Group className="mb-3 me-1">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 me-1">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 me-1">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="font-open fs-15 fw-500 text-royal-blue text-capitalize me-3">
                      Verifyed
                    </p>
                    <p className="font-open fs-12 fw-600 text-uppercase text-dove-gray me-3">
                      otp
                    </p>
                    <Form.Group className="mb-3 me-1">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 me-1">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 me-1">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="text-center form-control-secondary"
                        type="text"
                        placeholder="0"
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <p className="text-tundora fs-21 fw-500 font-open mb-3">
                Location
              </p>
              <Row>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Select aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Select aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Control type="text" placeholder="land mark" />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Control type="email" placeholder="pin code" />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Control type="email" placeholder="name of project" />
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col>
              <h5 className="text-tundora fs-22 fw-700 font-open mb-3 text-capitalize">
                posting property for
              </h5>
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                sale
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                rent/lease
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                pg/hostel
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                roommate
              </Button>{" "}
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col>
              <h5 className="text-tundora fs-22 fw-700 font-open mb-3 text-capitalize">
                property type
              </h5>
              <p className="text-dove-gray fs-16 fw-500 font-open mb-3 text-capitalize">
                all residential
              </p>
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                independed house
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                villa
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                bunglow
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                builder floar apartment
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                pentouse
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                studio apartment
              </Button>{" "}
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col>
              <p className="text-dove-gray fs-16 fw-500 font-open mb-3 text-capitalize">
                all commercial
              </p>
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                office space
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                shop
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                showroom
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                wherehouse/godown
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                industrial land
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                indusrtial building
              </Button>{" "}
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col>
              <p className="text-dove-gray fs-16 fw-500 font-open mb-3 text-capitalize">
                all agricultural
              </p>
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                agricultural land
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                {" "}
                <BiCheck className="active-check" />
                farm house
              </Button>{" "}
            </Col>
          </Row>

          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12}>
              <h5 className="text-tundora fs-22 fw-700 font-open mb-3 text-capitalize">
                for flat
              </h5>
            </Col>
            <Col md={6} className="mb-4">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize">
                bedroom{" "}
              </p>
              <div className="d-flex justify-content-start align-items-center">
                <Button variant="outline-secondary" className="btn-circle">
                  1
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  2
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  3
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  4
                </Button>{" "}
                <Form.Group className="mb-2">
                  <Form.Select className="d-inline-block w-auto h-auto rounded-pill shadow-none py-2">
                    <option>5+</option>
                    <option value="1">5</option>
                    <option value="2">7</option>
                    <option value="3">8</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={6} className="mb-4">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize">
                balconies{" "}
              </p>
              <div className="d-flex justify-content-start align-items-center">
                <Button variant="outline-secondary" className="btn-circle">
                  1
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  2
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  3
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  4
                </Button>{" "}
                <Form.Group className="mb-2">
                  <Form.Select className="d-inline-block w-auto h-auto rounded-pill shadow-none py-2">
                    <option>5+</option>
                    <option value="1">5</option>
                    <option value="2">7</option>
                    <option value="3">8</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={6} className="mb-4">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize">
                floor no.
              </p>
              <div className="d-flex justify-content-start">
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  lower basement
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  upper basement
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-check-arrow">
                  <BiCheck className="active-check" />
                  ground
                </Button>{" "}
              </div>
            </Col>
            <Col md={6} className="mb-4">
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize">
                total floors{" "}
              </p>
              <div className="d-flex justify-content-start align-items-center">
                <Button variant="outline-secondary" className="btn-circle">
                  1
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  2
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  3
                </Button>{" "}
                <Button variant="outline-secondary" className="btn-circle">
                  4
                </Button>{" "}
                <Form.Group className="mb-2">
                  <Form.Select className="d-inline-block w-auto h-auto rounded-pill shadow-none py-2">
                    <option>5+</option>
                    <option value="1">5</option>
                    <option value="2">7</option>
                    <option value="3">8</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize">
                furnished status{" "}
              </p>
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                furnished
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                unfurnished
              </Button>{" "}
              <Button variant="outline-secondary" className="btn-check-arrow">
                <BiCheck className="active-check" />
                semi-furnished
              </Button>{" "}
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12}>
              <h5 className="text-tundora fs-22 fw-700 font-open mb-3 text-capitalize">
                Area{" "}
              </h5>
            </Col>
            <Col md={3}>
              <InputGroup className="mb-3 combo-input">
                <Form.Control placeholder="Carpet Area" />
                <Form.Select>
                  <option>Sq-Ft</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col md={3}>
              <InputGroup className="mb-3 combo-input">
                <Form.Control placeholder="Super Built-Up Area" />
                <Form.Select>
                  <option>Sq-Ft</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col md={3}>
              <Button
                variant="primary"
                className=" font-open fs-15 fw-500 text-royal-blue text-capitalize my-2 bg-transparent border-0 text-royal-blue"
              >
                + Super Built-Up Area
              </Button>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col xs={12} className="my-2">
              <h5 className="text-tundora fs-22 fw-700 font-open mb-3 text-capitalize">
                Transaction Type{" "}
              </h5>
              {["radio"].map((type) => (
                <div
                  key={`inline-${type}`}
                  className="mb-3 fs-15 text-dove-gray text-capitalize font-open fw-400"
                >
                  <Form.Check
                    inline
                    label="First Sale"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="Resale"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
            <Col xs={12} className="d-flex my-2">
              <span className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize pe-3">
                Possession Status{" "}
              </span>
              {["radio"].map((type) => (
                <div
                  key={`inline-${type}`}
                  className="mb-3 fs-15 text-dove-gray text-capitalize font-open fw-400"
                >
                  <Form.Check
                    inline
                    label="Under Construction"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="Ready to Move"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4} className="mb-4">
              <div className="d-flex justify-content-start align-items-center">
                <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                  Possession year
                </p>
                <Form.Group>
                  <Form.Select className="mb-3 rounded-pill">
                    <option>Year</option>
                    <option value="1">2022</option>
                    <option value="2">2021</option>
                    <option value="3">2020</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="d-flex justify-content-start align-items-center">
                <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                  Age of Construction
                </p>
                <Form.Group>
                  <Form.Select className="mb-3 rounded-pill">
                    <option>Year</option>
                    <option value="1">2022</option>
                    <option value="2">2021</option>
                    <option value="3">2020</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 d-flex align-items-center">
            <Col xs={12}>
              <h5 className="text-tundora fs-22 fw-700 font-open mb-3 text-capitalize">
                Price Details
              </h5>
            </Col>
            <Col md={4}>
              <div className="d-flex justify-content-start align-items-center">
                <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                  Expected Price
                </p>
                <Form.Group>
                  <Form.Select className="mb-3 rounded-pill">
                    <option>Year</option>
                    <option value="1">2022</option>
                    <option value="2">2021</option>
                    <option value="3">2020</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-flex justify-content-start align-items-center">
                <p className="text-emperor fs-16 fw-500 font-open mb-3 text-capitalize me-3">
                  Booking/Token Amount
                </p>
                <Form.Group>
                  <Form.Select className="mb-3 rounded-pill">
                    <option>Year</option>
                    <option value="1">2022</option>
                    <option value="2">2021</option>
                    <option value="3">2020</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {["checkbox"].map((type) => (
                <div
                  key={`default-${type}`}
                  className="mb-3 d-flex fs-15 text-code-gray text-capitalize"
                >
                  <Form.Check
                    type={type}
                    label="PLC"
                    id={`default-${type}`}
                    className="me-3"
                  />

                  <Form.Check
                    type={type}
                    label=" Car Parking"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Club Membership"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                  <Form.Check
                    type={type}
                    label="Stamp Duty (Register changes excluded)"
                    id={`disabled-default-${type}`}
                    className="me-3"
                  />
                </div>
              ))}
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col md={6} className="d-flex">
              <p className="text-emperor fs-16 fw-500 font-open mb-0 mt-2 text-capitalize me-3">
                Maintenance Charges
              </p>
              <div>
                <InputGroup className="mb-3 combo-input">
                  <Form.Control placeholder="Carpet Area" />
                  <Form.Select>
                    <option>Monthly</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </InputGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </UserDashBoardLayout>
    </>
  );
};

export default PostYourProperty;
