import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const SidebarUser = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavLink
            to="/user-dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            User dashboard
          </NavLink>
          <NavLink
            to="/find-property"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Find properties
          </NavLink>
          <NavLink
            to="/find-builders"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            find builders
          </NavLink>
          <NavLink
            to="/find-agents"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            find agents
          </NavLink>
          <NavLink
            to="/event-list"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            find events
          </NavLink>
          <NavLink
            to="/property-details-single"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            posted requirements
          </NavLink>
          <NavLink
            to="/user-history"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            logdata/history
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            home loan
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            gov site links
          </NavLink>
          <NavLink
            to="/user-profile"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            profile
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            sign out
          </NavLink>
          {/* <NavLink to="/admin-dashboard">
            className=
            {({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
            Admin Dasboard
          </NavLink> */}
        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  );
};

export default SidebarUser;
