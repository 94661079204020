import React from "react";
import { Button } from "react-bootstrap";
import service1 from "../../assets/img/service1.png";
import service2 from "../../assets/img/service2.png";
import service3 from "../../assets/img/service3.png";
import service4 from "../../assets/img/service4.png";

const ServiceCard = () => {
  const Service = [
    {
      id: 1,
      Title: "banglore",
      img: service1,
    },
    {
      id: 2,
      Title: "Apaartments",
      img: service2,
    },
    {
      id: 3,
      Title: "Retail Shop",
      img: service3,
    },
    {
      id: 4,
      Title: "Agriculture Land ",
      img: service4,
    },
  ];
  return (
    <>
      <div className="text-center text-white">
        <h1 className="font-rubic text-white fw-500">Post Your Requrement</h1>
        <p className="fs-14 font-rubic fw-500 text-white">
          Reference site about Lorem Ipsum,{" "}
        </p>
        <p className="my-4 fs-20 font-rubic fw-500 text-dusty-gary text-justify px-sm-0 px-md-5">
          Reference site about Lorem Ipsum, giving information on its origins,
          as well as a random Lipsum generator. Reference site about Lorem
          Ipsum, giving information on its origins, as well as a random Lipsum
          generator. Reference site about Lorem Ipsum, giving information on its
          origins, as well as a random Lipsum generator. Reference site about
          Lorem Ipsum, giving information on its origins, as well as a random
          Lipsum generator.
        </p>

        <Button variant="light" className="btn-light-custom">
          MORE Events
        </Button>

        <div className="d-block d-md-flex justify-content-between align-items-center mt-5">
          {Service.map((Item) => (
            <div key={Item.id}>
              <img src={Item.img} alt="" />
              <p className="fs-18 font-open fw-500 text-dusty-gary text-capitalize my-3">
                {Item.Title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
