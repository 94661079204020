import React from "react";
import { Col, Row, Tab, Tabs, Form, Button } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
const Filters = () => {
  return (
    <>
      <div className="fill-tab-sec">
        <div className="bg-white rounded py-5 px-3 px-md-5 shadow-lg">
          <Tabs
            defaultActiveKey="buy"
            id="uncontrolled-tab-example"
            className="mb-4 border-0 bg-white rounded"
          >
            <Tab eventKey="buy" title="Buy">
              <Row className="mb-0 mt-2">
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Property Type">
                    <option>Property Type</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Location">
                    <option>Location</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Col className="d-grid">
                  <Button variant="primary" className="abtn-primary-custom">
                    <FiSearch className="me-1" /> search now
                  </Button>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="rent" title="Rent">
              <Row className="mb-0 mt-2">
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Property Type">
                    <option>Property Type</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Location">
                    <option>Location</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Col className="d-grid">
                  <Button variant="primary" className="abtn-primary-custom">
                    <FiSearch className="me-1" /> search now
                  </Button>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="plots" title="Plots">
              <Row className="mb-0 mt-2">
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Property Type">
                    <option>Property Type</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Location">
                    <option>Location</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Col className="d-grid">
                  <Button variant="primary" className="abtn-primary-custom">
                    <FiSearch className="me-1" /> search now
                  </Button>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="flats" title="Flats">
              <Row className="mb-0 mt-2">
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Property Type">
                    <option>Property Type</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Location">
                    <option>Location</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Col className="d-grid">
                  <Button variant="primary" className="abtn-primary-custom">
                    <FiSearch className="me-1" /> search now
                  </Button>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="commercial" title="Commercial">
              <Row className="mb-0 mt-2">
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Property Type">
                    <option>Property Type</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select defaultValue="Location">
                    <option>Location</option>
                    <option>...</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control type="text" placeholder="Enter" />
                </Form.Group>
                <Col className="d-grid">
                  <Button variant="primary" className="abtn-primary-custom">
                    <FiSearch className="me-1" /> search now
                  </Button>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Filters;
