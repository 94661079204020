import React from "react";
import { Row, Form, Col, Container, Button } from "react-bootstrap";

const EventRegistrationForm = () => {
  return (
    <>
      <section>
        <Container>
          <Row className="mb-3">
            <Col xs={12}>
              <h2>Event Registration</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
                nihil fugiat magni sunt nesciunt quam debitis.
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Row>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Control type="text" placeholder="Event title" />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Control type="email" placeholder="Venue" />
                </Form.Group>
              </Row>
            </Col>
            <Col xs={12}>
              <h5>schedule</h5>
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-start align-items-center">
                <Form.Label>Time</Form.Label>
                <Form.Group className="mb-3 me-1 w-100">
                  <Form.Control className="" type="time" placeholder="0" />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Form.Label>Date</Form.Label>
                <Form.Group className="mb-3 me-1 w-100">
                  <Form.Control className="" type="date" placeholder="0" />
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Event Description"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col xs={12}>
                  <h5>schedule</h5>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Email ID"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Mobile No."
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 me-1">
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Website Link"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Other</Form.Label>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control as="textarea" rows={4} />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Button variant="primary">Submit</Button>{" "}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EventRegistrationForm;
