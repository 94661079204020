import React, { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import HeaderDashboard from "../headers/HeaderDashboard";
import BottomBar from "../BottomBar";
import TopBar from "../TopBar";
import { FaUserAlt } from "react-icons/fa";
import UseerDetailCard from "../../AllPanels/UserPannel/UserDetails/UseerDetailCard";
import SidebarAdminPannel from "../sidebars/SidebarAdminPannel";
import HeaderAdminPannel from "../headers/HeaderAdminPannel";

const AdminPannelDashBoardLayout = (props) => {
  const { children } = props;

  // THis is for mobile sidebar
  const [sidebar, setSidebar] = useState(false);
  const handleSidebar = () => setSidebar(true);
  const handleSidebarClose = () => setSidebar(false);

  const [usersidebar, setUserSidebar] = useState(false);
  const handleUserSidebar = () => setUserSidebar(true);
  const handleUserSidebarClose = () => setUserSidebar(false);

  // This is dashboard side bar for desktop
  const [isActive, setActive] = useState("false");
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
    setActive(!isActive);
  };
  return (
    <>
      <div className="bg-White-Lilac">
        <HeaderAdminPannel
          toggle={toggle}
          handleToggle={handleToggle}
          sidebar={sidebar}
          handleSidebar={handleSidebar}
          usersidebar={usersidebar}
          handleUserSidebar={handleUserSidebar}
        />
        <div className="header-divider"></div>

        <main
          className={
            isActive ? "myreal-container active " : "myreal-container-close"
          }
        >
          <aside className="sidebar d-none d-md-none d-lg-flex">
            <SidebarAdminPannel />
          </aside>

          <section className="main-content bg-White-Lilac">
            {/* <div className="header-divider"></div> */}
            <TopBar />

            <section className="px-3">{children}</section>
            <BottomBar />
          </section>
        </main>
        {/* main Sidebar For mobile */}
        <Offcanvas
          show={sidebar}
          onHide={handleSidebarClose}
          placement="start"
          name="start"
          scroll={false}
          backdrop={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Real Estate</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <Sidebar /> */}
            <SidebarAdminPannel />
          </Offcanvas.Body>
        </Offcanvas>
        {/* User SideBar for mobie */}
        <Offcanvas
          show={usersidebar}
          onHide={handleUserSidebarClose}
          placement="end"
          name="end"
          scroll={false}
          backdrop={false}
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>
            <FaUserAlt size={18} className="me-2 align-baseline" /> User Name
          </Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <UseerDetailCard />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default AdminPannelDashBoardLayout;
