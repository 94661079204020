import React from "react";
import { Tabs, Tab, Row, Col, Image } from "react-bootstrap";
import BigImg from "../../assets/img/photo1.png";
const EventTsbs = () => {
  return (
    <>
      <div className="event-tabs">
        <Tabs
          defaultActiveKey="ctab-1"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="ctab-1" title="Details">
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Doloremque assumenda tenetur quibusdam eius, illo quod neque
                libero possimus ab, enim nisi cum quo dolores illum vitae
                explicabo. Reiciendis, iure deserunt?
              </p>
              <div>
                <Row>
                  <Col>
                    <h6>Over View</h6>
                    <ul className="ps-3">
                      <li>Lorem ipsum dolor, sit</li>
                      <li>Lorem ipsum dolor, sit</li>
                      <li>Lorem ipsum dolor, sit</li>
                      <li>Lorem ipsum dolor, sit</li>
                      <li>Lorem ipsum dolor, sit</li>
                      <li>Lorem ipsum dolor, sit</li>
                    </ul>
                  </Col>
                  <Col>
                    <Image src={BigImg} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
            </div>
          </Tab>
          <Tab eventKey="ctab-2" title="Schedule">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque assumenda tenetur quibusdam eius, illo quod neque
              libero possimus ab, enim nisi cum quo dolores illum vitae
              explicabo. Reiciendis, iure deserunt?
            </p>
          </Tab>
          <Tab eventKey="ctab-3" title="Gallery">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque assumenda tenetur quibusdam eius, illo quod neque
              libero possimus ab, enim nisi cum quo dolores illum vitae
              explicabo. Reiciendis, iure deserunt?
            </p>
          </Tab>
          <Tab eventKey="ctab-4" title="Videos">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque assumenda tenetur quibusdam eius, illo quod neque
              libero possimus ab, enim nisi cum quo dolores illum vitae
              explicabo. Reiciendis, iure deserunt?
            </p>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default EventTsbs;
