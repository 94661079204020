import React from "react";
import { Col, Container, Row, Button, Card, Image } from "react-bootstrap";
// import Homeimg from "../../../src/assets/imghome.png";
import Homeimg from "../../assets/img/house.png";

const FinanceSection = () => {
  const Finance = [
    {
      id: 1,
      img: Homeimg,
      tile: "Home Loan",
    },
    {
      id: 2,
      img: Homeimg,
      tile: "Home Loan",
    },
    {
      id: 3,
      img: Homeimg,
      tile: "Home Loan",
    },
    {
      id: 4,
      img: Homeimg,
      tile: "Home Loan",
    },
  ];
  return (
    <>
      <section className="py-5">
        <Container className="">
          <Row className="d-flex justify-content-center mb-5">
            <Col xs={12} md={7} className="text-center">
              <h1>Finance</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestiae mollitia sed velit? Nisi accusamus provident nihil
                cumque enim nam ea rem ex, dolor
              </p>
            </Col>
          </Row>
          <Row xs={1} md={4} className="g-4">
            {Finance.map((items) => (
              <Col key={items.id}>
                <Card className="finance-card p-4 recomend-card">
                  <Card.Body>
                    <div className="financeimg-box">
                      <Image className="img-fluid" src={items.img} />
                    </div>
                    <Card.Title>Home Loan</Card.Title>
                    <Card.Text>This is a longer card with</Card.Text>
                    <Button variant="dark" className="btn-dark px-4">
                      {" "}
                      apply now
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="d-flex justify-content-center">
            <Col className="text-center py-5">
              <Button variant="dark" className="btn-dark-custom">
                Know More
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FinanceSection;
