import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const SidebarAgent = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavLink
            to="/agent-dashboard"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Agent Dashboard
          </NavLink>
          <NavLink
            to="/agent-listed-property"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Listed Properties
          </NavLink>
          <NavLink
            to="/agent-event-list"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Events
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Properties Short Details
          </NavLink>
          <NavLink
            to="/agent-history"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            LogData/History
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Home Loan
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            gov site links
          </NavLink>
          <NavLink
            to="/agent-Offerce"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Packages
          </NavLink>
          <NavLink
            to="/not-found"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            Support
          </NavLink>
          <NavLink
            to="/agent-news"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            News
          </NavLink>
          <NavLink
            to="/agent-profile"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            profile
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            sign out
          </NavLink>
        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  );
};

export default SidebarAgent;
