import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Collapse,
  Image,
} from "react-bootstrap";
import OwnerCard from "../UserPannel/properties/OwnerCard";
import { AiFillStar } from "react-icons/ai";
import BigImg from "../../assets/img/photo1.png";
import EnquryForm from "../../component/Home/EnquryForm";
import AgentDashBoardLayout from "../../layout/DashBoardLayouts/AgentDashBoardLayout";
import EventTsbs from "../../views/Event/EventTsbs";

const AgentEventDetails = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AgentDashBoardLayout>
        <section className="pb-5">
          <Container>
            <Row className="d-flex justify-content-between align-items-center py-3 ">
              <Col md={8}>
                <h1 className="text-capitalize text-shark font-rubic fw-500">
                  Event title lorem ipsum
                </h1>
                <p className="mb-3 fs-16 font-rubic fw-400 text-silver-chalice">
                  Trimurti nagar, nagpur, nagpur mahasrastra
                </p>
                <div>
                  {/* <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/1hLBCOlptq8"
                  ></iframe> */}
                  <Image
                    src={BigImg}
                    className="img-fluid"
                    style={{ minHeight: "280px" }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <h3 className="text-capitalize text-royal-blue font-rubic fw-500 mb-5">
                  ₹ 65 lac{" "}
                  <span className="fs-16 text-shark fw-400">
                    @ 5,846 per sq.ft.
                  </span>
                </h3>
                <Row>
                  <Col xs={12}>
                    <div>
                      <Image
                        src={BigImg}
                        className="img-fluid mb-3 mt-2"
                        style={{ minHeight: "130px" }}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div>
                      <Image
                        src={BigImg}
                        className="img-fluid"
                        style={{ minHeight: "140px" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              {/* <Col md={4}>
                <Row>
                  <Col md={12}>
                    <h3 className="text-capitalize text-royal-blue font-rubic fw-500">
                      ₹ 65 lac{" "}
                      <span className="fs-16 text-shark fw-400">
                        @ 5,846 per sq.ft.
                      </span>
                    </h3>
                  </Col>
                </Row>
              </Col> */}
            </Row>
            {/* New sction */}
            <Row>
              <Col md={8}>
                <Row>
                  <Col>
                    {/* New section */}
                    <Card className="shadow mb-5 p-2">
                      <Card.Body className="pt-4">
                        <Row>
                          <Col md={3}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Event Type
                            </h6>
                            <p className="text-code-gray fs-16 fw-500 text-capitalize">
                              Web Development
                            </p>
                          </Col>
                          <Col md={3}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Speaker
                            </h6>
                            <p className="text-code-gray fs-16 fw-500 text-capitalize">
                              10 Best Speaker
                            </p>
                          </Col>
                          <Col md={3}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Sponsor
                            </h6>
                            <p className="text-code-gray fs-16 fw-500 text-capitalize">
                              Event Lab
                            </p>
                          </Col>
                          <Col md={3}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              <AiFillStar />
                            </h6>
                            <p className="text-code-gray fs-16 fw-500 text-capitalize">
                              500+
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    {/* New section */}
                    <Card className="shadow mb-5 p-2">
                      <Card.Body>
                        <EventTsbs />
                      </Card.Body>
                    </Card>
                    {/* New section */}
                    <Card className="shadow mb-5 p-2">
                      <Card.Body>
                        <h2 className="text-tundora fs-21 fw-700 font-open mb-4">
                          Overview
                        </h2>
                        <Row>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Power Backups
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              lift
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Maintainance staff
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              security
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              car parking
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              gymnasium
                            </h6>
                          </Col>
                        </Row>
                        <div>
                          <div className="text-center my-3">
                            <Button
                              onClick={() => setOpen(!open)}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                              variant="outline-primary"
                            >
                              view all
                            </Button>
                          </div>
                          <Collapse in={open}>
                            <div id="example-collapse-text">
                              <Row>
                                <Col md={4}>
                                  <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                                    Power Backups
                                  </h6>
                                </Col>
                                <Col md={4}>
                                  <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                                    lift
                                  </h6>
                                </Col>
                                <Col md={4}>
                                  <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                                    Maintainance staff
                                  </h6>
                                </Col>
                                <Col md={4}>
                                  <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                                    security
                                  </h6>
                                </Col>
                                <Col md={4}>
                                  <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                                    car parking
                                  </h6>
                                </Col>
                                <Col md={4}>
                                  <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                                    gymnasium
                                  </h6>
                                </Col>
                              </Row>
                            </div>
                          </Collapse>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EnquryForm />
                  </Col>
                </Row>
              </Col>

              <Col md={4}>
                <OwnerCard />
              </Col>
            </Row>
          </Container>
        </section>
      </AgentDashBoardLayout>
    </>
  );
};

export default AgentEventDetails;
