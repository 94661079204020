import React, { useState } from "react";
import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import { HiPlusCircle } from "react-icons/hi";
import EventRegistrationForm from "../../views/Event/EventRegistrationForm";

const EnquryForm = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="enqury-sec py-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col sm={12} md={6}>
              <p className="fs-20 fw-300 font-open text-black">
                Need assistance? your wish is our command.
              </p>
              <h3 className="fw-600 text-black">
                we'll help you find your right property..
              </h3>
            </Col>
            <Col sm={12} md={6} className="text-center">
              <Button
                variant="danger"
                className="rounded-pill text-uppercase px-4"
                onClick={handleShow}
              >
                {" "}
                <HiPlusCircle /> submite your requrment
              </Button>
              <p className="fs-16 font-open fw-500 text-code-gray3 mt-3 mb-0">
                in less than one minutg
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventRegistrationForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnquryForm;
