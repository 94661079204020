import React from "react";
import AdminPannelDashBoardLayout from "../../layout/DashBoardLayouts/AdminPannelDashBoardLayout";

const AdminDashboard = () => {
  return (
    <>
      <AdminPannelDashBoardLayout>
        <h1>Under Development</h1>
      </AdminPannelDashBoardLayout>
    </>
  );
};

export default AdminDashboard;
