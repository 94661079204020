import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserDashBoardLayout from "../../../layout/DashBoardLayouts/UserDashBoardLayout";
import OwnerCard from "../properties/OwnerCard";
import VideoCardDetailed from "../../../component/VideoCardDetailed";
import ShotVideoCard from "../../../component/ShortVideoCard";
import { RecomedSlides, ShortVideoSLides } from "../../../data/Data";
import UserDetailsCard from "../../../component/UserDetailsCard";

const UserDetails = () => {
  return (
    <>
      <UserDashBoardLayout>
        <Container>
          <Row>
            <Col>
              <UserDetailsCard />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Row className="px-3">
                {RecomedSlides.map((Item) => (
                  <Col md={4} key={Item.id} className="p-0">
                    <VideoCardDetailed
                      ClassProperty="mx-1 mb-3 recomend-card"
                      title={Item.title}
                      propertImage={Item.propertImage}
                      clientname={Item.clientname}
                      timeline={Item.timeline}
                    />
                  </Col>
                ))}
              </Row>
              <Row className="px-3">
                {ShortVideoSLides.map((Item) => (
                  <Col md={3} key={Item.id} className="p-0">
                    <ShotVideoCard
                      ClassProperty=""
                      Thumbnel={Item.url}
                      title={Item.title}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={4}>
              <OwnerCard />
            </Col>
          </Row>
        </Container>
      </UserDashBoardLayout>
    </>
  );
};

export default UserDetails;
