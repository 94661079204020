import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Form,
  Collapse,
  Image,
} from "react-bootstrap";
import UserDashBoardLayout from "../../../layout/DashBoardLayouts/UserDashBoardLayout";
import OwnerCard from "./OwnerCard";
import Bigimg from "../../../assets/img/photo1.png";

const PropertyDetails = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <UserDashBoardLayout>
        <Container>
          <Row>
            <Col>
              <Image src={Bigimg} className="img-fluid" />
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center py-3 ">
            <Col md={8}>
              <h1 className="text-capitalize text-shark font-rubic fw-500">
                2 bh aprtment -1200 sq ft
              </h1>
              <p className="mb-0 fs-16 font-rubic fw-400 text-silver-chalice">
                Trimurti nagar, nagpur, nagpur mahasrastra
              </p>
            </Col>
            <Col md={4}>
              <h2>65 lac </h2>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center py-3 ">
            <Col md={8}>
              <div>
                {/* <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/1hLBCOlptq8"
                  ></iframe> */}
              </div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <div>
                    {/* <iframe
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/1hLBCOlptq8"
                      ></iframe> */}
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    {/* <iframe
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/1hLBCOlptq8"
                      ></iframe> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* New sction */}
          <Row>
            <Col md={8}>
              {/* New section */}
              <Card className="shadow mb-5 p-2">
                <Card.Body>
                  <h4 className="text-tundora fs-21 fw-700 font-open mb-4">
                    Overview
                  </h4>
                  <Row>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        Bed Rooms
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        2
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        Batch Rooms
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        3
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        Sale status
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        New
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        Buldup area
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        1300 sq ft
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        furnishing status
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        furnished
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        carpet area
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        1300 sq ft
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        super buildup area
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        1300 sq ft
                      </p>
                    </Col>
                    <Col md={3}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        possesion status
                      </h6>
                      <p className="text-code-gray fs-16 fw-500 text-capitalize">
                        ready to move
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* New section */}
              <Card className="shadow mb-5 p-2">
                <Card.Body>
                  <h2 className="text-tundora fs-21 fw-700 font-open mb-4">
                    More Details
                  </h2>
                  <Form>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        rental value
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="2 lac"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        total floars
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="2 floar"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        total flats
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="10 flats"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        price start
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="20 lac"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        RERA id
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="824043380"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        Min & Max Carpet
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="Min & Max Carpet"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        Min & Max Post size
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="1100 sq ft & 200 sq ft"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        detail address
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="warli see face mumbai"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        near by developer
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="pranay masulkar"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        aprecician factory
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="aprecician factory"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        power backups
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="yes"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        loan offerdbanks
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="SBI, ICICI, HDFC"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-0" controlId="">
                      <Form.Label
                        className="mb-0 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice"
                        column
                        sm="4"
                      >
                        EMI starts
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          className="text-code-gray fs-16 fw-500 text-capitalize"
                          plaintext
                          readOnly
                          defaultValue="15000"
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
              {/* New section */}
              <Card className="shadow mb-5 p-2">
                <Card.Body>
                  <h2 className="text-tundora fs-21 fw-700 font-open mb-4">
                    Overview
                  </h2>
                  <Row>
                    <Col md={4}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        Power Backups
                      </h6>
                    </Col>
                    <Col md={4}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        lift
                      </h6>
                    </Col>
                    <Col md={4}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        Maintainance staff
                      </h6>
                    </Col>
                    <Col md={4}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        security
                      </h6>
                    </Col>
                    <Col md={4}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        car parking
                      </h6>
                    </Col>
                    <Col md={4}>
                      <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                        gymnasium
                      </h6>
                    </Col>
                  </Row>
                  <div>
                    <div className="text-center my-3">
                      <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        variant="outline-primary"
                      >
                        view all
                      </Button>
                    </div>
                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <Row>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Power Backups
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              lift
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              Maintainance staff
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              security
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              car parking
                            </h6>
                          </Col>
                          <Col md={4}>
                            <h6 className="mb-2 fs-16 font-rubic text-capitalize fw-400 text-silver-chalice">
                              gymnasium
                            </h6>
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <OwnerCard />
            </Col>
          </Row>
        </Container>
      </UserDashBoardLayout>
    </>
  );
};

export default PropertyDetails;
